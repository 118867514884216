var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{ref:"query",staticClass:"column",attrs:{"id":"steam-items-list","skip":_vm.skip || (!_vm.user && !_vm.steamId),"query":gql => gql`
      query getUserSteamItems(
        $steamId: String
        $commission: Float
        $appId: AppIdEnum
        $showUnTradable: Boolean
        $tradeUrl: String
        $partnerUserId: ID
      ) {
        getUserSteamItems(
          steamId: $steamId
          commission: $commission
          appId: $appId
          showUnTradable: $showUnTradable
          tradeUrl: $tradeUrl
          partnerUserId: $partnerUserId
        ) {
          disabledSteamItemsCount
          maxSteamItemsCount
          minSteamItemPrice
          steamItems {
            ...SteamItem
          }
        }
      }
      ${_vm.SteamItemFragment}
    `,"variables":{
    steamId: _vm.steamId,
    commission: _vm.commission[_vm._appId],
    appId: { 730: 'CSGO', 570: 'DOTA2', 252490: 'RUST', 440: 'TF2' }[_vm._appId],
    showUnTradable: _vm.showUnTradable,
    tradeUrl: _vm.tradeUrl,
    partnerUserId: _vm.partnerUserId
  }},on:{"error":_vm.onError,"result":_vm.onResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading, data, error }, query, isLoading }){return [_c('div',{staticClass:"col column full-width steam-items-list q-pa-md"},[_c('div',{staticClass:"row q-mb-md"},[_c('div',{staticClass:"col-md col-12"},[_c('q-input',{staticClass:"full-width input__semi-borderless",attrs:{"dark":"","debounce":125,"color":"primary","label":_vm.$t('steamItemsList.search'),"disable":!data ||
                !data.getUserSteamItems ||
                !data.getUserSteamItems.steamItems.length,"label-color":"white","input-class":"text-white","clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"size":"sm","name":"fas fa-search","color":"subtitle-color"}})]},proxy:true}],null,true),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"q-pl-md-sm q-mt-md flex no-wrap justify-between q-mt-md-none",class:{
            'col-all': _vm.$q.platform.is.mobile,
            'col-auto': !_vm.$q.platform.is.mobile
          },staticStyle:{"max-height":"60px"}},[(_vm.selectedGame && _vm.gamesEnabled)?_c('q-btn',{staticClass:"full-height q-mr-md text-weight-bold",attrs:{"color":"primary","padding":"10px","push":""}},[_c('q-icon',{attrs:{"size":"lg","name":`img:${_vm.selectedGame.icon}`}}),_c('q-menu',{attrs:{"content-class":"bg-transparent no-shadow"}},[_c('q-list',{staticClass:"bg-transparent q-pt-xs",attrs:{"dense":""}},_vm._l((_vm.games),function(game,i){return (game.appId !== _vm._appId)?_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],key:i,staticStyle:{"padding-left":"0 !important","padding-right":"0 !important"},attrs:{"dense":""}},[_c('q-btn',{staticClass:"full-height q-mr-md text-weight-bold",attrs:{"color":"primary","padding":"10px","push":""},on:{"click":function($event){return _vm.changeAppId(game.appId)}}},[_c('q-icon',{attrs:{"size":"lg","name":`img:${game.icon}`}})],1)],1):_vm._e()}),1)],1)],1):_vm._e(),_c('q-btn',{staticClass:"full-height q-mr-md text-weight-bold",attrs:{"icon":_vm.$q.platform.is.mobile ? `fas fa-check-double` : undefined,"color":"primary","push":"","title":_vm.$t('steamItemsList.select_all'),"label":!_vm.$q.platform.is.mobile
                ? _vm.$t('steamItemsList.select_all')
                : undefined,"disable":(!_vm.steamId && !_vm.user) || !_vm.allowedSteamItems.length},on:{"click":_vm.selectAll}}),_c('q-btn',{staticClass:"full-height q-mr-md",attrs:{"icon":_vm.sortBy === null
                ? 'fas fa-minus'
                : _vm.sortBy === 'PRICE_DESC'
                ? 'fas fa-sort-amount-down'
                : 'fas fa-sort-amount-down-alt',"color":"primary","push":"","title":_vm.$t('steamItemsList.change_sorting'),"disable":(!_vm.steamId && !_vm.user) || !_vm.showedSteamItems.length},on:{"click":_vm.toggleSort}}),_c('q-btn',{ref:"refreshBtn",staticClass:"full-height",attrs:{"icon":"fas fa-sync-alt","color":"primary","push":"","title":_vm.$t('steamItemsList.update_inventory'),"disable":!_vm.steamId && !_vm.user},on:{"click":() => {
                query.refetch();
                _vm.$emit(`update:selectedSteamItems`, []);
              }}})],1)]),_c('div',{staticClass:"col relative-position flex"},[((!data && !error) || isLoading)?_c('div',{staticClass:"absolute absolute-center full-width text-center"},[(_vm.skip || (!_vm.user && !_vm.steamId))?[_vm._t("auth-required",function(){return [_c('div',{staticClass:"q-pb-xs",domProps:{"innerHTML":_vm._s(_vm.$t('steamItemsList.must_auth'))}}),_c('AuthBtn',{staticStyle:{"max-width":"250px","width":"100%"}})]})]:_c('CircleLoading',{attrs:{"size":"3rem"}})],2):(data && data.getUserSteamItems)?_c('div',{staticClass:"full-width",staticStyle:{"flex-grow":"1"}},[(!data.getUserSteamItems.steamItems.length)?_c('div',{staticClass:"absolute absolute-center text-center error-message"},[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.empty_inventory"))+" ")]):(_vm.showedSteamItems.length)?[_c('q-scroll-area',{staticClass:"full-height",staticStyle:{"margin-right":"-8px"},attrs:{"thumb-style":{ width: '4px' },"visible":true}},[_c('fade-transition',{staticClass:"row q-col-gutter-sm q-mr-sm",attrs:{"group":"","duration":250,"appear":""}},_vm._l((_vm.showedSteamItems),function(steamItem,i){return _c('div',{key:steamItem.index,staticClass:"col-md-3 col-6"},[_c('SteamItem',_vm._b({class:{
                      'cursor-pointer': steamItem.price >= _vm.minSteamItemPrice
                    },attrs:{"once":_vm.$q.platform.is.mobile ? i <= 6 : i <= 28,"disabled":steamItem.price < _vm.minSteamItemPrice,"selected":_vm.selectedSteamItems.find(
                        item => item.index === steamItem.index
                      ),"show-usd-price":true},nativeOn:{"click":function($event){steamItem.price >= _vm.minSteamItemPrice
                        ? _vm.selectSteamItem(steamItem)
                        : undefined}}},'SteamItem',steamItem,false))],1)}),0)],1)]:_c('div',{staticClass:"absolute absolute-center text-center error-message"},[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.zero_result"))+" ")])],2):(error)?_c('div',{staticClass:"absolute-center absolute text-center error-message"},[(error.message.indexOf(`your inventory is private`) !== -1)?[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.hidden_inventory"))+" "),_c('span',{staticClass:"text-weight-bold text-primary cursor-pointer",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.openURL(`https://steamcommunity.com/id/me/edit/settings`)}}},[_vm._v(_vm._s(_vm.$t("steamItemsList.hidden_inventory_link")))])]:(
              error.message.indexOf(`authorization is required`) !== -1
            )?[_vm._t("auth-required",function(){return [_c('div',{staticClass:"q-pb-xs",domProps:{"innerHTML":_vm._s(_vm.$t('steamItemsList.must_auth'))}}),_c('AuthBtn',{staticStyle:{"max-width":"250px","width":"100%"}})]})]:[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.load_error"))+" "),_c('span',{staticClass:"text-weight-bold text-primary cursor-pointer",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return query.refetch()}}},[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.load_error_refresh")))]),_vm._v(" "+_vm._s(_vm.$t("steamItemsList.load_error1"))+" "),_c('span',{staticClass:"text-negative text-weight-bold"},[_vm._v("«"+_vm._s(_vm.getErrorCodeByMessage(error.message))+"»")])]],2):_vm._e()])]),(data && data.getUserSteamItems)?_c('div',{staticClass:"text-left text-subtitle-color disabled-items-notify q-mt-lg"},[_c('q-icon',{staticClass:"q-pr-xs",staticStyle:{"padding-bottom":"2px"},attrs:{"name":"fas fa-info-circle"}}),_vm._v(" "+_vm._s(_vm.$t("steamItemsList.inventory_total_price", { steamItemsTotalPrice: _vm.steamItemsTotalPrice }))+" "),(_vm.showTotalInUsd)?[_vm._v("/ $"+_vm._s((_vm.steamItemsTotalPrice / ((_vm.balanceRates || {}).USD || 1)).toFixed(2)))]:_vm._e(),(data.getUserSteamItems.disabledSteamItemsCount)?[_vm._v(" "+_vm._s(_vm.$t("steamItemsList.disabled_items", { disabledSteamItemsCount: data.getUserSteamItems.disabledSteamItemsCount }))+" ")]:_vm._e()],2):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }