
import { SteamBotOfferFragment } from "@/graphql/fragments";
import { Component, Prop, Vue } from "vue-property-decorator";
import LiveOffer from "./LiveOffer.vue";
import * as _ from "lodash";

@Component({
  components: {
    LiveOffer
  }
})
export default class LiveOffers extends Vue {
  @Prop() partnerUserId;
  SteamBotOfferFragment = SteamBotOfferFragment;
  getSteamBotDepositOffers: Array<any> = [];
  hasMore = true;
  loading = false;

  onDepositSteamBotOfferAdded(previousResult, { subscriptionData }) {
    const newResult = {
      ...previousResult,
      getSteamBotDepositOffers: previousResult.getSteamBotDepositOffers.map(
        offer => Object.assign({}, offer)
      )
    };
    const offerAdded = subscriptionData.data.subscribeDepositSteamBotOfferAdded;
    newResult.getSteamBotDepositOffers.unshift(offerAdded);

    return newResult;
  }

  scrollHandler(query) {
    const position =
      //@ts-ignore
      this.$refs.pan.scrollWidth -
      //@ts-ignore
      this.$refs.pan.scrollLeft -
      //@ts-ignore
      this.$refs.pan.clientWidth;

    if (position < 250 && !this.loading && this.hasMore) {
      this.loading = true;
      this.fetchMore(query, () => (this.loading = false));
    }
  }

  onResult(result) {
    if (result && result.data && result.data.getSteamBotDepositOffers) {
      this.getSteamBotDepositOffers = result.data.getSteamBotDepositOffers;
    }
  }

  async fetchMore(query, done) {
    await query.fetchMore({
      variables: {
        nextPageToken: _.last(this.getSteamBotDepositOffers)._id,
        partnerUserId: this.partnerUserId
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          fetchMoreResult.getSteamBotDepositOffers.length === 0
        ) {
          this.hasMore = false;
          return prev;
        }
        return Object.assign({}, prev, {
          getSteamBotDepositOffers: [
            ...prev.getSteamBotDepositOffers,
            ...fetchMoreResult.getSteamBotDepositOffers
          ]
        });
      }
    });
    done();
  }

  handlePan({ delta }) {
    //@ts-ignore
    this.$refs.pan.scrollLeft = this.$refs.pan.scrollLeft - delta.x;
  }
}
