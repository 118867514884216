import { render, staticRenderFns } from "./AdvantagesFooter.vue?vue&type=template&id=940863ae&scoped=true&"
import script from "./AdvantagesFooter.vue?vue&type=script&lang=ts&"
export * from "./AdvantagesFooter.vue?vue&type=script&lang=ts&"
import style0 from "./AdvantagesFooter.vue?vue&type=style&index=0&id=940863ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940863ae",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QImg,QColor});
