var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-page',[_c('ApolloQuery',{staticClass:"q-pb-md-lg",attrs:{"query":gql => gql`
      query getDepositOfferCommission {
        getDepositOfferCommission
      }
    `,"id":"home"},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data, error }, isLoading }){return [(_vm.offer)?_c('SteamTradeOffer',{attrs:{"steam-items":_vm.selectedSteamItems,"offer":_vm.offer},on:{"close":_vm.steamTradeOfferDialogClose}}):_vm._e(),_vm._m(0),_c('div',{staticClass:"home-main wrapper q-pt-lg q-py-mb-lg user-select-none"},[_c('div',{staticClass:"flex column q-mb-lg"},[_c('span',{staticClass:"text-h5 text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("home.four_steps_for"))+" ")]),_c('span',{staticClass:"text-subtitle1 text-grey text-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("home.sell_your_skins"))+" ")])]),(!_vm.$q.platform.is.mobile)?_c('div',{staticClass:"row rounded bg-block full-width q-pa-md q-pa-md-lg vertical-divider"},[_c('div',{staticClass:"col-md-7 q-pr-md-xl column"},[_c('HomeHeading',{staticClass:"q-pt-md"},[_c('div',{staticClass:"flex items-center"},[_vm._v(" "+_vm._s(_vm.$t("home.enter_your_trade"))+" "),_c('span',{staticClass:"text-primary q-ml-xs cursor-pointer text-weight-bold",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.openURL(
                    'http://steamcommunity.com/id/id/tradeoffers/privacy#trade_offer_access_url'
                  )}}},[_vm._v(_vm._s(_vm.$t("home.link")))]),_c('q-btn',{staticClass:"q-ml-xs",attrs:{"size":"xs","round":"","flat":"","icon":"fas fa-question-circle"}},[_c('q-tooltip',{attrs:{"dark":"","anchor":"top middle","self":"center middle","transition-show":"scale","transition-hide":"scale","content-class":"bg-dark shadow-10 text-center","content-style":"font-size: 14px"}},[_vm._v(" "+_vm._s(_vm.$t("home.if_inventory_not_load"))+" ")])],1)],1)]),_c('TradeUrlInput'),_c('HomeHeading',{staticClass:"q-pt-md"},[_vm._v(" "+_vm._s(_vm.$t("home.choose_skins_for_sale"))+" ")]),_c('q-intersection',{staticClass:"col steam-items-list-intersection",attrs:{"once":""}},[_c('SteamItemsList',{staticClass:"full-height",attrs:{"app-id":_vm.appId,"commission":data && data.getDepositOfferCommission
                  ? data.getDepositOfferCommission
                  : {},"trade-url":_vm.user ? _vm.user.tradeUrl || undefined : undefined,"selectedSteamItems":_vm.selectedSteamItems},on:{"update:appId":function($event){_vm.appId=$event},"update:app-id":function($event){_vm.appId=$event},"update:selectedSteamItems":function($event){_vm.selectedSteamItems=$event},"update:selected-steam-items":function($event){_vm.selectedSteamItems=$event}}})],1)],1),_c('div',{staticClass:"col-12 col-md-5 q-pl-md-xl q-pt-lg q-pt-md-none"},[_c('HomeHeading',{staticClass:"q-mt-lg"},[_vm._v(" "+_vm._s(_vm.$t("home.choose_payment_system"))+" ")]),_c('HomeSelectPaymentSystem',{attrs:{"selectedPaymentSystem":_vm.selectedPaymentSystem},on:{"update:selectedPaymentSystem":function($event){_vm.selectedPaymentSystem=$event},"update:selected-payment-system":function($event){_vm.selectedPaymentSystem=$event}}}),_c('HomeHeading',{staticClass:"q-mt-lg"},[_vm._v(" "+_vm._s(_vm.$t("home.enter_recipient"))+" ")]),(_vm.selectedPaymentSystem)?_c('HomeBalanceWithdraw',{attrs:{"app-id":_vm.appId,"steamItemCommission":data && data.getDepositOfferCommission
                ? data.getDepositOfferCommission
                : 0,"offerPrice":_vm.offerPrice,"recipient":_vm.recipient,"selectedPaymentSystem":_vm.selectedPaymentSystem,"selectedSteamItems":_vm.selectedSteamItems},on:{"done":_vm.createOfferDone,"update:recipient":function($event){_vm.recipient=$event}}}):_vm._e()],1)]):_c('q-stepper',{staticClass:"bg-block",staticStyle:{"margin-left":"-23px","margin-right":"-23px","border":"none","border-radius":"0"},attrs:{"vertical":"","color":"primary","dark":"","animated":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('q-step',{attrs:{"name":1,"title":_vm.$t('home.enter_your_trade'),"caption":_vm.$t('home.if_inventory_not_load'),"icon":"fas fa-link","done":_vm.step > 1}},[_vm._v(" "+_vm._s(_vm.$t("home.get_trade_url"))+" "),_c('span',{staticClass:"text-primary cursor-pointer text-weight-bold",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.openURL(
                'http://steamcommunity.com/id/id/tradeoffers/privacy#trade_offer_access_url'
              )}}},[_vm._v(_vm._s(_vm.$t("home.here")))]),_c('TradeUrlInput',{staticClass:"q-mt-sm"}),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"color":"primary","label":_vm.$t('common.next')},on:{"click":function($event){_vm.step = 2}}})],1)],1),_c('q-step',{attrs:{"name":2,"title":_vm.$t('home.choose_payment_system'),"caption":_vm.$t('home.choose_payment_system_caption'),"icon":"fab fa-cc-visa","done":_vm.step > 2}},[_c('HomeSelectPaymentSystem',{attrs:{"selectedPaymentSystem":_vm.selectedPaymentSystem},on:{"update:selectedPaymentSystem":function($event){_vm.selectedPaymentSystem=$event},"update:selected-payment-system":function($event){_vm.selectedPaymentSystem=$event}}}),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"color":"primary","label":_vm.$t('common.next')},on:{"click":function($event){_vm.step = 3}}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":_vm.$t('common.back')},on:{"click":function($event){_vm.step = 1}}})],1)],1),_c('q-step',{attrs:{"name":3,"title":_vm.$t('home.choose_skins_for_sale'),"caption":`${_vm.$t('home.choose_skins_for_sale_caption', {
              minWithdrawAmount: _vm.selectedPaymentSystem.minWithdrawAmount
            })} ${
              _vm.offerPrice > 0
                ? _vm.$t('home.choose_skins_for_sale_caption1', { offerPrice: _vm.offerPrice })
                : ``
            }`,"icon":"fab fa-steam","done":_vm.step > 3}},[_c('SteamItemsList',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 3),expression:"step === 3"}],staticClass:"col",attrs:{"app-id":_vm.appId,"commission":data && data.getDepositOfferCommission
                ? data.getDepositOfferCommission
                : 0,"trade-url":_vm.user ? _vm.user.tradeUrl || undefined : undefined,"selectedSteamItems":_vm.selectedSteamItems},on:{"update:appId":function($event){_vm.appId=$event},"update:app-id":function($event){_vm.appId=$event},"update:selectedSteamItems":function($event){_vm.selectedSteamItems=$event},"update:selected-steam-items":function($event){_vm.selectedSteamItems=$event}}}),_c('q-stepper-navigation',[_c('q-btn',{attrs:{"color":"primary","label":_vm.$t('common.next'),"disable":_vm.offerPrice < _vm.selectedPaymentSystem.minWithdrawAmount},on:{"click":function($event){_vm.step = 4}}},[(_vm.offerPrice < _vm.selectedPaymentSystem.minWithdrawAmount)?_c('q-tooltip',{attrs:{"dark":"","anchor":"center right","self":"center left","transition-show":"scale","transition-hide":"scale","content-class":"bg-dark shadow-10 text-center","content-style":"font-size: 14px"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.$t('home.choose_skins_tooltip', {
                    minWithdrawAmount: _vm.selectedPaymentSystem.minWithdrawAmount
                  })
                )}})]):_vm._e()],1),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":_vm.$t('common.back')},on:{"click":function($event){_vm.step = 2}}})],1)],1),_c('q-step',{attrs:{"name":4,"title":_vm.$t('home.enter_recipient'),"icon":"fas fa-pencil-alt"}},[(_vm.selectedPaymentSystem)?_c('HomeBalanceWithdraw',{attrs:{"app-id":_vm.appId,"steamItemCommission":data && data.getDepositOfferCommission
                ? data.getDepositOfferCommission
                : 0,"recipient":_vm.recipient,"offerPrice":_vm.offerPrice,"selectedPaymentSystem":_vm.selectedPaymentSystem,"selectedSteamItems":_vm.selectedSteamItems},on:{"done":_vm.createOfferDone,"update:recipient":function($event){_vm.recipient=$event}}}):_vm._e(),_c('q-stepper-navigation',[_c('q-btn',{staticClass:"q-ml-sm",attrs:{"flat":"","color":"primary","label":_vm.$t('common.back')},on:{"click":function($event){_vm.step = 3}}})],1)],1)],1)],1)]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"advantage-cards-container user-select-none"},[_c('div',{staticClass:"row wrapper q-py-md-lg q-py-md"},[_vm._l((_vm.advantagesCards),function(card,i){return [_c('q-card',{key:i,staticClass:"advantage-card col-12 col-sm bg-transparent flex justify-center no-wrap",attrs:{"flat":""}},[_c('q-card-section',{staticClass:"q-px-none q-pr-md q-pr-md-none q-px-md-md"},[_c('q-icon',{attrs:{"name":card.icon,"color":"primary","size":_vm.advantagesCardsSizes[_vm.$q.screen.name]}})],1),_c('q-card-section',{staticClass:"q-py-none q-pr-none q-pr-md-md",class:{ 'col-grow': _vm.$q.platform.is.mobile }},[_c('div',{staticClass:"advantage-card-title text-h5 text-weight-bold q-mb-md",class:{ 'text-h5': !_vm.$q.platform.is.mobile },domProps:{"innerHTML":_vm._s(card.title)}}),_c('div',{staticClass:"advantage-card-text text-subtitle2 text-subtitle-color",domProps:{"innerHTML":_vm._s(card.text)}})])],1)]})],2)])
}]

export { render, staticRenderFns }