
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    size: {
      type: String,
      default: "25px"
    }
  }
})
export default class CircleLoading extends Vue {}
