import { render, staticRenderFns } from "./LiveOffers.vue?vue&type=template&id=10d285ce&scoped=true&"
import script from "./LiveOffers.vue?vue&type=script&lang=ts&"
export * from "./LiveOffers.vue?vue&type=script&lang=ts&"
import style0 from "./LiveOffers.vue?vue&type=style&index=0&id=10d285ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d285ce",
  null
  
)

export default component.exports
import QScrollObserver from 'quasar/src/components/scroll-observer/QScrollObserver.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import TouchPan from 'quasar/src/directives/TouchPan.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollObserver,QIntersection,QSeparator});qInstall(component, 'directives', {TouchPan});
