import { render, staticRenderFns } from "./LiveOffer.vue?vue&type=template&id=5f426793&scoped=true&"
import script from "./LiveOffer.vue?vue&type=script&lang=ts&"
export * from "./LiveOffer.vue?vue&type=script&lang=ts&"
import style0 from "./LiveOffer.vue?vue&type=style&index=0&id=5f426793&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f426793",
  null
  
)

export default component.exports
import QCarousel from 'quasar/src/components/carousel/QCarousel.js';
import QCarouselSlide from 'quasar/src/components/carousel/QCarouselSlide.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCarousel,QCarouselSlide,QImg,QChip});
