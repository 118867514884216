
  import {Component, InjectReactive, Vue, Watch} from "vue-property-decorator";
import {APP_SCROLL_POS_SYMBOL, BALANCE_CURRENCY_SYMBOL, BALANCE_RATES_SYMBOL} from "../../constants";

class BalanceCurrency {
  constructor(public id: string, public icon: string) {}
}
const BALANCE_CURRENCY_COOKIE_NAME = "_sp_currency";

@Component({})
export default class CurrencySwitcher extends Vue {
  @InjectReactive(APP_SCROLL_POS_SYMBOL) public appScrollPos:
          | number
          | undefined;
  @InjectReactive(BALANCE_CURRENCY_SYMBOL)
  public balanceCurrency;

  currencies = [
    new BalanceCurrency("RUB", "fas fa-ruble-sign"),
    new BalanceCurrency("UAH", "fas fa-hryvnia"),
    new BalanceCurrency("USD", "fas fa-dollar-sign")
  ];
  activeCurrency = this.currencies[0];

  @Watch('balanceCurrency', {immediate: true})
 async currencyChanged(currencyId){
    await this.$nextTick();
    this.activeCurrency =  this.currencies.find(
            currency => currency.id === currencyId
    ) || this.activeCurrency;
  }

  created() {
    const currencyId =
      this.$q.cookies.get(BALANCE_CURRENCY_COOKIE_NAME) || undefined;
    const currency = this.currencies.find(
      currency => currency.id === currencyId
    );

    if (currency) {
      this.changeCurrency(currency);
    }
  }

  changeCurrency(currency) {
    this.activeCurrency = currency;
    this.$root.$emit("update:balanceCurrency", currency.id);
    this.$q.cookies.set(BALANCE_CURRENCY_COOKIE_NAME, this.balanceCurrency, {
      expires: 365,
      path: '/'
    });
  }
}
