
import {Component, InjectReactive, Prop, Vue} from "vue-property-decorator";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";
import {BALANCE_RATES_SYMBOL} from "@/constants";

@Component({
  components: {
    CurrencyIcon,
    Amount
  }
})
export default class SteamItem extends Vue {
  @InjectReactive(BALANCE_RATES_SYMBOL)
  public balanceRates: any;

  @Prop() _id: string | undefined;
  @Prop() name: string | undefined;
  @Prop() price: number | undefined;
  @Prop() image: string | undefined;
  @Prop() rarity: string | undefined;
  @Prop() rarityColor: string | undefined;
  @Prop() steamBotId: string | undefined;

  @Prop() selected: boolean | undefined;
  @Prop() disabled: boolean | undefined;
  @Prop() showUsdPrice: boolean | undefined;

  get quality() {
    if (!this.name) {
      return null;
    }

    const quality = this.name.match(/\(.+\)$/);
    return quality ? quality[0].replace(/[^A-Z]/g, "") : null;
  }

  get nameWithoutQuality() {
    if (!this.name) {
      return null;
    }

    return this.name.replace(/\(.+\)$/, "");
  }
}
