
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import openURL from 'quasar/src/utils/open-url.js';;
import { USER_PROFILE_SYMBOL } from "@/constants";
import TradeUrlInput from "@/components/user/TradeUrlInput.vue";
import HomeHeading from "@/components/home/HomeHeading.vue";
import SteamItemsList from "@/components/steam/SteamItemsList.vue";
import HomeSelectPaymentSystem from "@/components/home/HomeSelectPaymentSystem.vue";
import HomeBalanceWithdraw from "@/components/home/HomeBalanceWithdraw.vue";
import { paymentSystems } from "@/components/home/constants";
import * as _ from "lodash";
import SteamTradeOffer from "@/components/steam/SteamTradeOffer.vue";

@Component({
  components: {
    TradeUrlInput,
    HomeHeading,
    SteamItemsList,
    HomeSelectPaymentSystem,
    HomeBalanceWithdraw,
    SteamTradeOffer
  },
  meta() {
    return { title: this.$t("home.title") };
  }
})
export default class Home extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  public appId:number = 730;
  public step = 1;
  public openURL = openURL;
  public selectedPaymentSystem = paymentSystems[0];
  public selectedSteamItems: any[] = [];
  public advantagesCardsSizes: any = Object.freeze({
    xs: "90px",
    sm: "30px",
    md: "55px",
    lg: "70px",
    xl: "70px"
  });
  public advantagesCards: any = Object.freeze([
    {
      icon: `icon-i-app-wallet`,
      title: this.$t("home.advantagesCards.0.title"),
      text: this.$t("home.advantagesCards.0.text")
    },
    {
      icon: `icon-i-app-hand-with-money`,
      title: this.$t("home.advantagesCards.1.title"),
      text: this.$t("home.advantagesCards.1.text")
    },
    {
      icon: `icon-i-app-piggy-bank`,
      title: this.$t("home.advantagesCards.2.title"),
      text: this.$t("home.advantagesCards.2.text")
    }
  ]);
  offer: string | null = null;
  recipient = "";

  get offerPrice() {
    return this.selectedSteamItems
      ? Number(
          _.sum(
            this.selectedSteamItems.map(steamItem => steamItem.price)
          ).toFixed(2)
        )
      : 0;
  }

  createOfferDone(offer) {
    this.offer = offer;
  }

  steamTradeOfferDialogClose(success) {
    this.offer = null;
    if (success) {
      this.selectedSteamItems = [];
      this.selectedPaymentSystem = paymentSystems[0];
      this.step = 1;
      this.recipient = "";
    }
  }
}
