
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import { USER_PROFILE_SYMBOL } from "@/constants";
import AuthBtn from "@/components/user/AuthBtn.vue";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";
import CurrencySwitcher from "@/components/balance/CurrencySwitcher.vue";

@Component({
  components: {
    AuthBtn,
    CurrencyIcon,
    CurrencySwitcher,
    Amount
  }
})
export default class BaseDrawer extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
}
