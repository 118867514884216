
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import {BALANCE_CURRENCY_SYMBOL, BALANCE_RATES_SYMBOL} from "../../constants";

@Component({})
export default class Amount extends Vue {
  @InjectReactive(BALANCE_RATES_SYMBOL) balanceRates: any;
  @Prop() currency!: string;
  @InjectReactive(BALANCE_CURRENCY_SYMBOL)
  public balanceCurrency;
  @Prop({ default: 0 }) value!: number;

  get _currency(){
    return this.currency || this.balanceCurrency;
  }
}
