import Vue from "vue";

Vue.filter("convertBalance", function(
  value: number,
  to: string,
  balanceRates: any
) {
  let isCrypto =
    to.indexOf("BTC") !== -1 || to.indexOf("ETH") !== -1

    return Number((value / balanceRates[to]).toFixed(isCrypto ? 6 : 2));
});

Vue.filter("convertBalanceInverse", function(
  value: number,
  from: string,
  balanceRates: any
) {
  return Number((value * balanceRates[from]).toFixed(2));
});
