export default Object.freeze({
  common: {
    next: "Next",
    back: "Back",
    cancel: "Cancel",
    close: "Close",
    another: "Another",
    pause: "Technical break, <br /> we'll be back any minute"
  },
  home: {
    title: "Sell of skins",
    advantagesCards: [
      {
        title: "Referral <br> program",
        text: "Share your <br> link with friends"
      },
      {
        title: "Without hidden <br> commissions",
        text: "You see total sum <br> instantly"
      },
      {
        title: "Instant <br> payouts",
        text: "Time of receipt of <br> funds ~15 minutes"
      }
    ],
    four_steps_for: "4 steps for",
    sell_your_skins: "sell your skins",
    enter_your_trade: "Enter your trade",
    link: "link",
    enter_your_trade_link: "Enter your trade link",
    if_inventory_not_load: "Enter link if your inventory isn't loaded",
    choose_skins_for_sale: "Select skins for sale",
    choose_skins_for_sale_caption: "Not less than {minWithdrawAmount} rub.",
    choose_skins_for_sale_caption1: "Selected for {offerPrice} rub.",
    choose_skins_tooltip:
      "Select skins <br /> worth at least {minWithdrawAmount} rub.",
    choose_payment_system: "Select payment system",
    enter_recipient: "Enter your payment details",
    get_trade_url: "Take your trade link",
    here: "here",
    choose_payment_system_caption: "It will receive money for your skins"
  },
  partner: {
    title: "Payment of invoice",
    three_steps_for: "3 steps for",
    deposit_balance: "top up your balance",
    enter_trade_link: "Enter your trade link to continue",
    accept_operation: "Confirm the operation"
  },
  tradeUrlInput: {
    success_notification: "Trade link has been successfully updated",
    wrong_link: "Incorrect link entered",
    wrong_link_format: "Incorrect link format",
    save: "SAVE"
  },
  steamItemsList: {
    search: "Search by name",
    select_all: "Select all",
    change_sorting: "Change sorting",
    update_inventory: "Refresh inventory",
    empty_inventory:
      "Your inventory for this game is empty or contains items we don't accept",
    zero_result: "No results were found for your search.",
    hidden_inventory:
      "Your inventory is hidden, please change your privacy settings by following the",
    hidden_inventory_link: "link",
    must_auth: "To continue, <br />you need to log in",
    load_error_refresh: "refresh",
    load_error: "Failed to load your inventory, please try to",
    load_error1:
      "it. If the error persists, report it to support, the error text:",
    inventory_total_price:
      "The cost of your inventory is {steamItemsTotalPrice} rub.",
    disabled_items:
      "You have {disabledSteamItemsCount} item(s) in your inventory we don't accept or have exchange restrictions",
    max_items: "You can select no more than {maxSteamItemsCount} skins."
  },
  homeBalanceWithdraw: {
    promocode_error: "Promo code is unavailable",
    promocode: "Promo code (optional)",
    bonus: "bonus",
    create_deposit_offer_error:
      "An error has occurred, please try again later. <br> If the error persists, contact support by specifying <br> error code",
    account_number: "Account number",
    deposit_paymentId: "Payment ID",
    deposit_paymentId_notification:
      "This payment is linked to your account, after payment the money will be credited to your balance.",
    account_number_error: "Account number is wrong",
    email: "Your e-mail",
    email_example: "Example: email@example.com",
    email_wrong: "E-mail is wrong",
    deposit_account: "Account balance top-up",
    deposit_account_notification:
      "Make sure to use the account that is linked to your account on the site to fund your Steam account, otherwise the funds may not be credited. ",
    items_cost: "Items cost:",
    commission: "Payment system's commission",
    total_payout: "Total payout:",
    min_total_payout: "Minimal payout:",
    additional_total_payout: "Additional payout for your level:",
    get_money: "Get money",
    confirm_email_notifications: "I agree to receive news and special offers",
    confirm: "By clicking on the button above, you agree to the",
    privacy_policy: "Privacy Policy",
    and: "and",
    terms_of_service: "Terms of Service"
  },
  partnerHeader: {
    title: "Deposit money <br /> to",
    caption: "Sell skins instantly <br /> and get real money!",
    btn: "Select skins"
  },
  steamTradeOffer: {
    rate_negative: `We are very sorry that you were not satisfied with the work of our service,
             we are doing our best to remedy this situation. For
             a prompt solution to the problem, please contact our service
             support.`,
    call_support: "Contact support.",
    rate_our_work2: `Please leave feedback on the work of our service by click on 
             <a
               style = "text-decoration: underline"
               class = "text-primary text-weight-bold"
               href = "https://www.trustpilot.com/evaluate/skins.plus"
               target = "_ blank"
               > the link </a
             >, You will greatly help us in growth. If you want to leave
             negative feedback, please contact support first, we
             we will definitely help you.`,
    rate_our_work: `Please rate the work of our service by clicking on the appropriate
             number of stars, where 1 star is very bad, 2 - bad, 3 -
             satisfactory, 4 - good, 5 - excellent`,
    transferred_items: "Received items",
    wait_accept: "We have received the offer, please wait...",
    processing_trade_offer: "Trade offer processing",
    remain_time: "Time left",
    success_trade:
      "The trade offer was successfully processed, the funds were credited to the specified details",
    error_trade: "We were unable to process your offer",
    trade: "Send the following items by",
    trade_link: "trade link",
    usage:
      "<span class=\"text-positive \">Attention! To prevent your items from being stolen - remove the <a rel='noreferrer' class='text-weight-bold text-primary cursor-pointer' style='text-decoration: underline' target=\"_blank\" href=\"https://steamcommunity.com/dev/apikey\"> Steam API Key</a>.</span> Send a trade offer with the selected items to our bot using the trade link. Make sure you are sending the exact items that were selected, because other items may not be rated and the trade offer will be refused. Be sure to verify the recipient of your items (including the account link), both when creating the trade offer and when confirming it in the Steam app.",
    give_items: "Send items",
    withdraw_error:
      "Failed to pay out automatically, <br> money has been transferred to your balance on the site, <br> try to pay out manually.",
    escrow_error:
      "Unfortunately, you don't have two-factor Steam authentication enabled, we are forced to reject your trade.",
    items_to_give_error: "Please don't take items from our bot.",
    tradeOfferStages: {
      waiting: "Waiting",
      accepting: "Accepting",
      payout: "Payout",
      completion: "Completion"
    }
  },
  statsFooter: {
    offers: "offers",
    completion: "completed",
    years: "years",
    experience: "experience with skins",
    satisfied: "satisfied",
    clients: "clients",
    minutes: "minutes",
    max_time: "max time for payout"
  },
  advantagesFooter: {
    advantage: "Advantages of",
    sell_items: {
      title: "Sell <br><span class='text-weight-light'>Steam skins!</span>",
      advantages: [
        "Minimal skin's cost is only 5 rub",
        "The highest prices",
        "Perfect system for accepting skins"
      ]
    },
    get: {
      title: "Get <br><span class='text-weight-light'>real money!</span>",
      advantages: [
        "More than 10 payment systems for withdrawal",
        "There is no commission for withdrawal",
        "Withdrawal takes approximately 10 minutes"
      ]
    },
    work: {
      title: "Earn <br><span class='text-weight-light'>with us!</span>",
      advantages: [
        "Invite your friends by referral",
        "Get 1-3% of their sales",
        "Withdraw the accumulated bonuses in a convenient way for you"
      ]
    }
  },
  liveOffers: {
    last: "Last",
    offers: "offers",
    anonymous: "Anonymous",
    by: "by"
  },
  baseFooter: {
    privacy_policy: "Privacy Policy",
    terms_of_service: "Terms of Service",
    caption:
      "A reliable service for selling Steam skins with fast payouts. <br /> Copyright © 2018-2023. All rights reserved."
  },
  baseHeader: {
    sell: "sell",
    buy: "buy",
    bonuses: "bonuses",
    deposit: "deposit",
    withdraw: "withdraw",
    reviews: "reviews",
    support: "support"
  },
  chat: {
    need_help: "Need help?",
    title: "Support chat (beta)",
    default_message: "Hello, how can I help you?",
    input_placeholder: "Write your question..."
  },
  homeHeader: {
    header:
      'Earn money on <br /> skins <span class = "text-weight-light"> now! </span>',
    caption: "Sell skins instantly <br /> and get real money!",
    buy_header:
      'Buy skins <br /> <span class = " text-weight-light "> instantly! </span>',
    buy_caption:
      "Instantly exchange real money <br /> and get cool skins without delay!",
    buy_skins: "Buy skins"
  },
  buy: {
    title: "Buy skins",
    steps: "3 steps for",
    buy_skins: "buy skins",
    enter_your_trade: "Enter your trade",
    enter_your_trade_link: "Enter your trade link",
    link: "link",
    choose_skins: "Select skins to buy",
    caption: "Skins will be sent via it",
    get_link: "You can get the link",
    here: "here",
    accept: "Confirmation"
  },
  skinsplusItemsList: {
    search: "Search by name",
    from: "from",
    to: "to",
    rub: "rub.",
    select_all: "Select all",
    from_zero: "from 0 rub.",
    to_max: "to 999.. rub.",
    sort: "Sort",
    close_filters: "Close add. filters",
    open_filters: "Open add. filters",
    reset_all: "Reset filters",
    type: "Item's type",
    type_weapon: "Weapon's type",
    rarity: "Rarity",
    exterior: "Exterior",
    zero_result: "No results were found for your search.",
    max_skins: "You can select no more than {maxSelectedCount} skins.",
    rarities: [
      "High Grade",
      "Superior",
      "Mil-Spec Grade",
      "Exotic",
      "Remarkable",
      "Contraband",
      "Classified",
      "Distinguished",
      "Consumer Grade",
      "Extraordinary",
      "Exceptional",
      "Master",
      "Restricted",
      "Base Grade",
      "Industrial Grade",
      "Covert"
    ],
    exteriors: [
      "Factory New",
      "Minimal Wear",
      "Field-Tested",
      "Well-Worn",
      "Battle-Scarred"
    ],
    tags: ["StatTrak™", "Souvenir", "Sticker"],
    types: [
      "Gift",
      "Weapon",
      "Pass",
      "Key",
      "Music Kit",
      "Container",
      "Gloves",
      "Graffiti",
      "Collectible"
    ],
    weaponTypes: [
      "SMG",
      "Machinegun",
      "Knife",
      "Sniper Rifle",
      "Rifle",
      "Shotgun",
      "Pistol"
    ]
  },
  skinsplusItemsCart: {
    empty_cart: "Your cart is empty",
    cost: "Cost:",
    items_count: "Items count:",
    count: "pcs.",
    buy: "Buy",
    enter_trade_link: "Enter your trade link",
    deposit: "Deposit",
    auth: "Authorization",
    empty_store: "Sorry, the items are already sold out",
    error: "An error occurred while creating the offer:"
  }
});
