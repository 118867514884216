
import {
  Component,
  Provide,
  ProvideReactive,
  Vue,
  Watch
} from "vue-property-decorator";
import BaseHeader from "../components/header/BaseHeader.vue";
import BaseFooter from "../components/footer/BaseFooter.vue";
import getCookie from "@/get-cookie";
import LiveOffers from "@/components/live-offers/LiveOffers.vue";
import BaseDrawer from "../components/header/BaseDrawer.vue";
import { UserFragment } from "@/graphql/fragments";
import {
  API_ENDPOINT_SYMBOL,
  BALANCE_CURRENCY_SYMBOL,
  BALANCE_RATES_SYMBOL,
  RIGHT_DRAWER_SHOW_SYMBOL,
  USER_PROFILE_SYMBOL
} from "@/constants";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import WithdrawDialog from "@/components/dialogs/WithdrawDialog.vue";
import DepositDialog from "@/components/dialogs/DepositDialog.vue";
import Chat from "@/components/chat/Chat.vue";
import FirebaseMessaging from "@/components/firebase-messaging/FirebaseMessaging.vue";
import FirebaseMessagingLocal from "@/components/firebase-messaging/FirebaseMessagingLocal.vue";
import gql from "graphql-tag";

@Component({
  components: {
    BaseFooter,
    Chat,
    FirebaseMessagingLocal,
    FirebaseMessaging,
    BaseHeader,
    BaseDrawer,
    CircleLoading,
    WithdrawDialog,
    DepositDialog,
    LiveOffers
  },
  apollo: {
    user: {
      query: gql`
        query {
          getMyProfile {
            ...User
          }
        }
        ${UserFragment}
      `,
      update(data) {
        this.loaded = true;
        return data.getMyProfile;
      },
      subscribeToMore: {
        document: gql`
          subscription subscribeUserUpdated {
            subscribeUserUpdated {
              ...User
            }
          }
          ${UserFragment}
        `,
        skip() {
          return !this.user;
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const update = Object.fromEntries(
            Object.entries(subscriptionData.data.subscribeUserUpdated).filter(
              ([key, value]) => key !== "__typename" && value !== null
            )
          );
          previousResult.getMyProfile = Object.assign(
            previousResult.getMyProfile,
            update
          );
          return previousResult;
        }
      }
    },

  },
  meta: {
    titleTemplate: title => `${title} - SKINSPLUS`
  }
})
export default class Layout extends Vue {
  @Provide(API_ENDPOINT_SYMBOL) public apiEndPoint: string =
    getCookie("http-endpoint") || process.env.VUE_APP_API_ENDPOINT;
  @ProvideReactive(RIGHT_DRAWER_SHOW_SYMBOL)
  public rightDrawerShow = false;
  @ProvideReactive(USER_PROFILE_SYMBOL)
  public user: any = null;
  public dialogs: any = {
    withdraw: false,
    deposit: false
  };
  public serverConnectionError = false;
  public loaded = false;

  get dialogOpened() {
    return !!Object.keys(this.dialogs).filter(key => this.dialogs[key]).length;
  }

  @Watch(`dialogs`, { deep: true })
  async dialogsWatcher(value) {
    Object.keys(value).forEach(key => {
      if (!value[key] && this.$route.hash && this.$route.hash === `#${key}`) {
        this.$router.push(this.$route.fullPath.replace(`#${key}`, ``));
      }
    });
  }

  @Watch(`$route.hash`, { immediate: true })
  async routeHashWatcher(value) {
    const result = {};
    Object.keys(this.dialogs).forEach(key => {
      result[key] = key === String(value).replace("#", "");
    });
    await this.$nextTick();
    this.$set(this, "dialogs", result);
  }

  created() {
    this.$q.addressbarColor.set("#171b23");
    setTimeout(() => {
      if (!this.loaded) {
        this.serverConnectionError = true;
      }
    }, 10000);

  }

  loadingAnimationDone() {
    setTimeout(() => this.$q.addressbarColor.set("#1B57B9"), 150);
  }

  @Watch("rightDrawerShow")
  rightDrawerShowWatcher(value) {
    if (value) {
      this.$q.addressbarColor.set("#171b23");
    } else {
      this.$q.addressbarColor.set("#1B57B9");
    }
  }
}
