
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SteamItem from "@/components/steam/SteamItem.vue";
import gql from "graphql-tag";
import openURL from 'quasar/src/utils/open-url.js';;

enum SteamDepositTradeOfferStage {
  WAIT = "WAIT",
  ACCEPT = "ACCEPT",
  PAYOUT = "PAYOUT",
  FINISHED = "FINISHED"
}

@Component({
  components: {
    SteamItem
  },
  apollo: {
    // Subscriptions
    $subscribe: {
      // When a tag is added
      subscribeDepositOfferStage: {
        query: gql`
          subscription subscribeDepositOfferStage($steamBotOfferId: ID!) {
            subscribeDepositOfferStage(steamBotOfferId: $steamBotOfferId) {
              stage
              state
              error
            }
          }
        `,
        variables() {
          return {
            steamBotOfferId: this.offer._id
          };
        },
        result({
          data: {
            subscribeDepositOfferStage: { stage, error, state }
          }
        }) {
          const tradeOfferStage = this.tradeOfferStages.find(
            ({ value }) => value === stage
          );

          if (tradeOfferStage) {
            tradeOfferStage.state = state;
            tradeOfferStage.error = error;

            if (
              tradeOfferStage.value === SteamDepositTradeOfferStage.WAIT &&
              state === "error"
            ) {
              setTimeout(() => (tradeOfferStage.state = "loading"), 3000);
            }
          }

          if (
            stage === SteamDepositTradeOfferStage.PAYOUT &&
            state === "error"
          ) {
            this.$q.notify({
              html: true,
              message: this.$t("steamTradeOffer.withdraw_error") as string,
              position: this.$q.platform.is.mobile ? "bottom" : "bottom-left",
              multiLine: true,
              type: "warning"
            });
          } else if (
            stage === SteamDepositTradeOfferStage.FINISHED &&
            state === "error"
          ) {
            this.tradeOfferStages.forEach(stage => (stage.state = "error"));
          } else if (
            stage === SteamDepositTradeOfferStage.WAIT &&
            state === "error"
          ) {
            error = String(error);

            const message =
              error.indexOf("escrow") !== -1
                ? (this.$t("steamTradeOffer.escrow_error") as string)
                : error.indexOf("itemsToGive") !== -1
                ? (this.$t("steamTradeOffer.items_to_give_error") as string)
                : error;

            this.$q.notify({
              html: true,
              message: message,
              position: this.$q.platform.is.mobile ? "bottom" : "bottom-left",
              multiLine: true,
              type: "warning"
            });
          }
        }
      }
    }
  }
})
export default class SteamTradeOffer extends Vue {
  openURL = openURL;
  rating = 0;
  SteamDepositTradeOfferStage = SteamDepositTradeOfferStage;
  @Prop() offer: any | undefined;
  @Prop() steamItems: any;
  show = true;

  tradeOfferStageStateIcons = {
    wait: "far fa-circle",
    error: "far fa-times-circle",
    loading: "fas fa-circle-notch",
    passed: "far fa-check-circle"
  };

  tradeOfferStages = [
    {
      state: "loading",
      error: "",
      label: this.$t("steamTradeOffer.tradeOfferStages.waiting"),
      value: SteamDepositTradeOfferStage.WAIT
    },
    {
      state: "wait",
      error: "",
      label: this.$t("steamTradeOffer.tradeOfferStages.accepting"),
      value: SteamDepositTradeOfferStage.ACCEPT
    },
    {
      state: "wait",
      error: "",
      label: this.$t("steamTradeOffer.tradeOfferStages.payout"),
      value: SteamDepositTradeOfferStage.PAYOUT
    },
    {
      state: "wait",
      error: "",
      label: this.$t("steamTradeOffer.tradeOfferStages.completion"),
      value: SteamDepositTradeOfferStage.FINISHED
    }
  ];

  @Watch("show")
  close() {
    const success =
      this.tradeOfferStages[this.tradeOfferStages.length - 1].state ===
      "passed";
    const error =
      this.tradeOfferStages[this.tradeOfferStages.length - 1].state === "error";
    this.$emit("close", success || error || undefined);
  }

  get offerCreatedAt() {
    return new Date(this.offer.createdAt).getTime();
  }

  get isPartnerPage() {
    return ["Partner", "PartnerWithPaymentId"].includes(this.$route.name || "");
  }

  cancelTradeOffer() {
    this.tradeOfferStages = this.tradeOfferStages.map(tradeOfferStage => {
      tradeOfferStage.state =
        tradeOfferStage.state !== "passed" ? "error" : tradeOfferStage.state;
      return tradeOfferStage;
    });
  }
}
