
import openURL from 'quasar/src/utils/open-url.js';;
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import {
  BALANCE_CURRENCY_SYMBOL,
  BALANCE_RATES_SYMBOL,
  USER_PROFILE_SYMBOL
} from "@/constants";
import { DepositPaymentSystem } from "@/components/home/PaymentSystem";
import { depositPaymentSystems } from "@/components/home/constants";
import gql from "graphql-tag";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";

@Component({
  components: {
    CurrencyIcon
  },
  apollo: {
    promocode: {
      update(data) {
        return data.getPromocode;
      },
      query: gql`
        query($code: String!) {
          getPromocode(code: $code) {
            code
            percentage
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          code: this.code
        };
      },
      skip() {
        if (!this.code) {
          this.promocode = null;
        }

        return !this.code;
      }
    }
  }
})
export default class DepositDialog extends Vue {
  @InjectReactive(BALANCE_CURRENCY_SYMBOL) public balanceCurrency;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  @InjectReactive(BALANCE_RATES_SYMBOL) public balanceRates;
  paymentSystem = depositPaymentSystems[0].value;
  amount: number | undefined = 0;
  paymentSystems: Array<DepositPaymentSystem> = depositPaymentSystems;
  payLink = "";
  code = "";
  promocode = null;
  @Prop() value;

  get minDepositAmount() {
    //@ts-ignore
    return this.$options.filters.convertBalance(
      this.selectedPaymentSystem.minDepositAmount,
      this.selectedPaymentSystem.currency,
      this.balanceRates
    );
  }

  openURL(link: string) {
    this.payLink = link;
    setTimeout(() => {
      //@ts-ignore
      this.$refs.payLink.click();
      this._value = false;
    }, 1000);
  }

  @Watch(`paymentSystem`)
  async clearForm() {
    await this.$nextTick();
    if (this.$refs.amountInput) {
      //@ts-ignore
      this.$refs.amountInput.validate();
    }
    this.amount = this.minDepositAmount;
  }

  get submitBtnDisable() {
    return (
      !this.selectedPaymentSystem ||
      !this.amount ||
      this.minDepositAmount > this.amount ||
      (!!this.code && !this.promocode)
    );
  }

  get selectedPaymentSystem() {
    const selectedPaymentSystem = this.paymentSystems.find(
      ({ value }) => this.paymentSystem === value
    );

    return selectedPaymentSystem || this.paymentSystems[0];
  }

  get amountWithCommission() {
    if (!this.selectedPaymentSystem) {
      return this.amount;
    }

    const amountWithCommission =
      this.amount === 0
        ? 0
        : Number(
            (
              (this.amount || 0) *
              (1 - (this.selectedPaymentSystem.commission || 0)) *
              this.balanceRates[this.selectedPaymentSystem.currency] *
             ( 1 / this.balanceRates[this.balanceCurrency])
            ).toFixed(2)
          );

    return amountWithCommission >= 0 ? amountWithCommission : 0;
  }

  created() {
    if (this.balanceCurrency === "UAH") {
      this.paymentSystem = "CARD_UAH";
    }

    this.amount = this.selectedPaymentSystem ? this.minDepositAmount : 5;
  }

  get _value() {
    return this.value;
  }

  set _value(v) {
    this.$emit(`input`, v);
  }
}
