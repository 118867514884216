var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{staticClass:"wrapper flex justify-between items-center",attrs:{"query":gql => gql`
      query getPartnerInfo($userId: ID!) {
        getPartnerInfo(userId: $userId) {
          name
        }
      }
    `,"id":"partner-header","variables":{ userId: _vm.$route.params.userId }},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data } }){return [(data)?_c('div',{staticClass:"full-height flex justify-center column"},[_c('div',{staticClass:"text-h3 text-weight-bold text-font-montserrat user-select-none",class:{ 'text-h5': _vm.$q.platform.is.mobile },style:(_vm.$q.platform.is.mobile ? 'font-size: 1.5rem' : '')},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('partnerHeader.title'))}}),_vm._v("   "),_c('span',{staticClass:"text-weight-light text-uppercase"},[_vm._v(_vm._s(data.getPartnerInfo.name))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"user-select-none text-h5 text-weight-medium text-font-montserrat text-home-header-description q-py-md-lg",class:{ 'text-h6 col flex items-center': _vm.$q.platform.is.mobile }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('partnerHeader.caption'))}})])]):_vm._e(),(!_vm.$q.platform.is.mobile)?_c('div',[_c('q-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#partner .partner-main',
            duration: 750,
            lazy: true,
            easing: 'ease-out',
            offset: _vm.$q.platform.is.mobile ? 0 : -100,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }),expression:"{\n            el: '#partner .partner-main',\n            duration: 750,\n            lazy: true,\n            easing: 'ease-out',\n            offset: $q.platform.is.mobile ? 0 : -100,\n            force: true,\n            cancelable: true,\n            x: false,\n            y: true\n          }"}],staticClass:"text-weight-bold q-mr-md-lg",attrs:{"size":_vm.$q.platform.is.mobile ? 'md' : 'xl',"padding":"15px 30px","label":_vm.$t('partnerHeader.btn'),"color":"primary","rounded":"","push":""}})],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }