<template>
  <div id="app">
    <q-scroll-area
      dark
      id="app-scroll"
      class="fullscreen"
      :bar-style="$q.platform.is.mobile ? { width: '0px' } : undefined"
      :thumb-style="$q.platform.is.mobile ? { width: '0px' } : undefined"
      @scroll="onAppScroll"
    >
      <router-view />
    </q-scroll-area>
  </div>
</template>

<script>
import { Component, ProvideReactive, Vue, Watch } from "vue-property-decorator";
import {
  APP_SCROLL_POS_SYMBOL,
  BALANCE_CURRENCY_SYMBOL,
  BALANCE_RATES_SYMBOL
} from "@/constants";
import gql from "graphql-tag";

@Component({
  components: {},
  apollo: {
    balanceRates: {
      query: gql`
        query {
          getBalanceRates
        }
      `,
      update(data) {
        return data.getBalanceRates || this.balanceRates;
      }
    }
  }
})
export default class App extends Vue {
  @ProvideReactive(APP_SCROLL_POS_SYMBOL) appScrollPos = 0;
  @ProvideReactive(BALANCE_CURRENCY_SYMBOL)
  balanceCurrency = "RUB";
  @ProvideReactive(BALANCE_RATES_SYMBOL)
  balanceRates = { RUB: 1, USD: 1 };

  onAppScroll({ verticalPosition }) {
    this.appScrollPos = verticalPosition;
  }

  created() {
    this.$i18n.locale = this.$q.lang
      .getLocale()
      .replace(/^([^-]+)(-.+)?$/, "$1");
    this.$moment.locale(this.$i18n.locale === "en" ? "uk" : this.$i18n.locale);
    this.$root.$on("update:balanceCurrency", v => {
      const currency = v.toUpperCase();

      if (currency in this.balanceRates) {
        this.balanceCurrency = currency;
      }
    });
  }

  @Watch("$route.fullPath", { immediate: true })
  hashRedirect(path) {
    if (path && path.startsWith("/#/")) {
      this.$router.replace(path.replace("/#/", "/"));
    }
  }
}
</script>

<style lang="scss">
@import "~animate.css";
@import "./styles/reset";
@import "./styles/app.scss";
@import "./assets/css/fontello.css";

body{
  .q-menu{
      border: #1a1f29 1px solid;
      box-shadow: 1px 1px 5px black;
    .mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .q-btn {
        width: 150px;
        height: 40px;
        margin-right: 16px;

      }
    }
  }
}
</style>
