
import { Component, Vue } from "vue-property-decorator";
import TrustPilotRating from "@/components/trustpilot/TrustPilotRating.vue";

@Component({
  components: {
    TrustPilotRating
  }
})
export default class ReviewsHeader extends Vue {}
