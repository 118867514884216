import { render, staticRenderFns } from "./FirebaseMessagingLocal.vue?vue&type=template&id=31c6a223&scoped=true&"
import script from "./FirebaseMessagingLocal.vue?vue&type=script&lang=ts&"
export * from "./FirebaseMessagingLocal.vue?vue&type=script&lang=ts&"
import style0 from "./FirebaseMessagingLocal.vue?vue&type=style&index=0&id=31c6a223&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c6a223",
  null
  
)

export default component.exports