
import { Component, Prop, Vue } from "vue-property-decorator";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";

@Component({
  components: {
    Amount,
    CurrencyIcon
  }
})
export default class LiveOffer extends Vue {
  @Prop() value;
  slide = 0;
}
