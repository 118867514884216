export class PaymentSystem {
  index = -1;

  constructor(
    public value: string | undefined,
    public label: string | null,
    public image: string | null,
    public mask: string | null,
    public fillMask: boolean | string | null,
    public example: string | null,
    public rule: RegExp | null,
    public imageWidth: number | null = 50,
    public imageHeight: number | null = 50,
    public commission: number | null,
    public fixedCommission: number | null,
    public minWithdrawAmount: number = 100,
    public currency: string = "RUB"
  ) {}
}

export class DepositPaymentSystem {
  constructor(
    public value: string | undefined,
    public label: string | null,
    public image: string | null,
    public commission: number,
    public minDepositAmount: number = 5,
    public currency: string = "RUB"
  ) {}
}
