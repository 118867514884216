import { render, staticRenderFns } from "./BaseHeader.vue?vue&type=template&id=21c3c71d&"
import script from "./BaseHeader.vue?vue&type=script&lang=ts&"
export * from "./BaseHeader.vue?vue&type=script&lang=ts&"
import style0 from "./BaseHeader.vue?vue&type=style&index=0&id=21c3c71d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QToolbarTitle,QBtn,QAvatar,QImg,QSeparator,QResizeObserver});
