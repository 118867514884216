
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import { USER_PROFILE_SYMBOL } from "@/constants";
import { UserFragment } from "@/graphql/fragments";

@Component({})
export default class TradeUrlInput extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  UserFragment = UserFragment;
  public tradeUrl = "";

  @Prop() public value: string | undefined;
  @Prop() public modelMode: boolean | undefined;

  onInput(value) {
    //@ts-ignore
    if (this.$refs.input.validate(value)) {
      this.$emit("update:value", value);
    }
  }

  created() {
    this.tradeUrl = this.modelMode
      ? this.value
      : this.user
      ? this.user.tradeUrl
      : "";
  }
}
