
import { Component, Vue } from "vue-property-decorator";
import AdvantagesFooter from "@/components/footer/AdvantagesFooter.vue";
import StatsFooter from "@/components/footer/StatsFooter.vue";
import TrustPilotReviews from "@/components/trustpilot/TrustPilotReviews.vue";
@Component({
  components: {
    AdvantagesFooter,
    StatsFooter,
    TrustPilotReviews
  }
})
export default class BaseFooter extends Vue {
  get isPartnerPage(){
    return ['Partner', 'PartnerWithPaymentId'].includes(this.$route.name || '');
  }
}
