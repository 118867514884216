var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper",attrs:{"id":"home-header"}},[_c('fade-transition',{attrs:{"mode":"out-in"}},[(_vm.$route.name !== 'Buy')?_c('div',{key:'home',staticClass:"full-height flex justify-center column"},[_c('trust-pilot-rating',{staticClass:"q-mb-md"}),_c('div',{staticClass:"text-h3 text-weight-bold text-font-montserrat user-select-none",class:{ 'text-h4': _vm.$q.platform.is.mobile },domProps:{"innerHTML":_vm._s(_vm.$t('homeHeader.header'))}}),_c('div',{staticClass:"user-select-none text-h5 text-weight-medium text-font-montserrat text-home-header-description q-py-md-lg",class:{ 'text-h6 col flex items-center': _vm.$q.platform.is.mobile },domProps:{"innerHTML":_vm._s(_vm.$t('homeHeader.caption'))}}),_c('div',{staticClass:"q-pb-lg q-pb-md-none"},[(!_vm.user)?_c('q-btn',{staticClass:"text-weight-bold q-mr-md-lg q-mb-md-none q-mb-lg",attrs:{"size":"lg","padding":"15px 30px","label":_vm.$t('homeBalanceWithdraw.get_money'),"color":"primary","rounded":"","push":"","loading":_vm.authLoading,"type":"a","href":`${_vm.apiEndPoint}auth/steam`},on:{"click":function($event){_vm.authLoading = true}}}):_c('q-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#home .home-main',
            duration: 750,
            lazy: true,
            easing: 'ease-out',
            // offset: -innerHeaderHeight,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }),expression:"{\n            el: '#home .home-main',\n            duration: 750,\n            lazy: true,\n            easing: 'ease-out',\n            // offset: -innerHeaderHeight,\n            force: true,\n            cancelable: true,\n            x: false,\n            y: true\n          }"}],staticClass:"text-weight-bold q-mr-md-lg q-mb-md-none q-mb-lg",attrs:{"size":"lg","padding":"15px 30px","label":_vm.$t('homeBalanceWithdraw.get_money'),"color":"primary","rounded":"","push":""}}),(_vm.$q.platform.is.mobile)?_c('br'):_vm._e(),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/qiwi-white.svg"),"alt":""}}),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/mastercard-white.svg"),"alt":""}}),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/visa-white.svg"),"alt":""}})],1)],1):_c('div',{key:'buy',staticClass:"full-height flex justify-center column"},[_c('div',{staticClass:"text-h3 text-weight-bold text-font-montserrat user-select-none",class:{ 'text-h4': _vm.$q.platform.is.mobile },domProps:{"innerHTML":_vm._s(_vm.$t('homeHeader.buy_header'))}}),_c('div',{staticClass:"user-select-none text-h5 text-weight-medium text-font-montserrat text-home-header-description q-py-md-lg",class:{ 'text-h6 col flex items-center': _vm.$q.platform.is.mobile },domProps:{"innerHTML":_vm._s(_vm.$t('homeHeader.buy_caption'))}}),_c('div',{staticClass:"q-pb-lg q-pb-md-none"},[(!_vm.user)?_c('q-btn',{staticClass:"text-weight-bold q-mr-md-lg q-mb-md-none q-mb-lg",attrs:{"size":"lg","padding":"15px 30px","label":_vm.$t('homeHeader.buy_skins'),"color":"primary","rounded":"","push":"","loading":_vm.authLoading,"type":"a","href":`${_vm.apiEndPoint}auth/steam`},on:{"click":function($event){_vm.authLoading = true}}}):_c('q-btn',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: '#buy .buy-main',
            duration: 750,
            lazy: true,
            easing: 'ease-out',
            // offset: -innerHeaderHeight,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }),expression:"{\n            el: '#buy .buy-main',\n            duration: 750,\n            lazy: true,\n            easing: 'ease-out',\n            // offset: -innerHeaderHeight,\n            force: true,\n            cancelable: true,\n            x: false,\n            y: true\n          }"}],staticClass:"text-weight-bold q-mr-md-lg q-mb-md-none q-mb-lg",attrs:{"size":"lg","padding":"15px 30px","label":_vm.$t('homeHeader.buy_skins'),"color":"primary","rounded":"","push":""}}),(_vm.$q.platform.is.mobile)?_c('br'):_vm._e(),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/qiwi-white.svg"),"alt":""}}),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/mastercard-white.svg"),"alt":""}}),_c('img',{staticClass:"q-mr-md",staticStyle:{"opacity":"0.5"},attrs:{"src":require("@/assets/img/payment-systems/visa-white.svg"),"alt":""}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }