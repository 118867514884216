import {
  PaymentSystem,
  DepositPaymentSystem
} from "@/components/home/PaymentSystem";

export const paymentSystems: Array<PaymentSystem> = [

  // new PaymentSystem(
  // `YANDEX`,
  // `ЮMoney (RUB)`,
  // require(`@/assets/img/payment-systems/yoomoney_logo.png`),
  // "################",
  // "X",
  // null,
  // /^41001\d{9,11}$/,
  // 715 / 6,
  // 150 / 6,
  // 0.015,
  // 0,
  // 100
  // ),

  /* new PaymentSystem(
      `PAYPALYCH_QIWI`,
      `Qiwi`,
      require(`@/assets/img/payment-systems/qiwi-white.svg`),
      "+#############",
      false,
      "Пример: +74951234567",
      /^\+?(375|7|380|998|373)\d{9,12}$/i,
      85 * 1.25,
      39 * 1.25,
      0.02,
      null,
      100
  ),*/

  new PaymentSystem(
      `ALIKASSA_CARD`,
      `Карта (RUB)`,
      null,
      "#### #### #### ####",
      "X",
      null,
      /^\d{16}$/,
      57,
      49,
      0.02,
      0,
      1000
  ),

  new PaymentSystem(
      `CRYPTO_PAYMENTS_TRC20_USDT`,
      `USDT (TRC20)`,
      null,
      '',
      '',
      'Пример: TJBFHgJGWyUsHskHGtBCmpXdRPcVjUwb3R',
      /^T[a-zA-Z\d]{33}$/,
      undefined,
      undefined,
      0,
      1,
      500,
      "USDT"
  ),
  new PaymentSystem(
      `CRYPTO_PAYMENTS_ERC20_USDT`,
      `USDT (ERC20)`,
      null,
      '',
      '',
      'Пример: 0x6b7eE97c73F63F800E8bB4e3d1EB4617bD229A95',
      /^0x[a-fA-F0-9]{40}$/,
      undefined,
      undefined,
      0,
      1,
      500,
      "USDT"
  ),
  new PaymentSystem(
      undefined,
      `Баланс сайта`,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      5
  )
 /* new PaymentSystem(
      `ALIKASSA_CARD_UAH`,
      `Карта (UAH)`,
      //require(`@/assets/img/payment-systems/card-uah-logo.png`),
      null,
      "#### #### #### ####",
      "X",
      null,
      /^\d{16}$/,
      57,
      49,
      0.04,
      0,
      100,
      'UAH'
  ),*/

  /*new PaymentSystem(
  `PAYPALYCH_CARD`,
  `Карта (RUB)`,
  null,
  "#### #### #### ####",
  "X",
  null,
  /^\d{16}$/,
  57,
  49,
  0.03,
  50,
  100
  ),*/
 /* new PaymentSystem(
  `CARD_UAH`,
  `Карта (UAH)`,
  //require(`@/assets/img/payment-systems/card-uah-logo.png`),
  null,
  "#### #### #### ####",
  "X",
  null,
  /^\d{16}$/,
  57,
  49,
  0.04,
  0,
  100,
      'UAH'
  ),*/
  /*new PaymentSystem(
      `ENOT_USDT`,
      `USDT (TRC20)`,
      // require(`@/assets/img/payment-systems/icons/usdt.png`),
      null,
      '',
      '',
      'Пример: TJBFHgJGWyUsHskHGtBCmpXdRPcVjUwb3R',
      /^T[a-zA-Z\d]{33}$/,
      undefined,
      undefined,
      0.01,
      0,
      7000,
      "USDT"
  ),*/
  /*new PaymentSystem(
      `USDT_TRC20`,
      `USDT (TRC20)`,
      // require(`@/assets/img/payment-systems/icons/usdt.png`),
      null,
      '',
      '',
      'Пример: TJBFHgJGWyUsHskHGtBCmpXdRPcVjUwb3R',
      /^T[a-zA-Z\d]{33}$/,
      undefined,
      undefined,
      0.01,
      0,
      500,
      "USDT"
  ),*/
].map((v, i) => {
  v.index = i;
  return v;
});

export const withdrawPaymentSystems: Array<PaymentSystem> = [
 /* new PaymentSystem(
    `PAYPALYCH_QIWI`,
    `Qiwi (RUB)`,
    require(`@/assets/img/payment-systems/icons/logo-qiwi.webp`),
    "+#############",
    false,
    "Пример: +74951234567",
    /^\+?(375|7|380|998|373)\d{9,12}$/i,
    undefined,
    undefined,
    0.02,
    null,
    100
  ),*/
  new PaymentSystem(
      `ALIKASSA_CARD`,
      `VISA / Mastercard / МИР (RUB)`,
      require(`@/assets/img/payment-systems/icons/logo-card.webp`),
      "#### #### #### ####",
      "X",
      null,
      /^\d{16}$/,
      undefined,
      undefined,
      0.02,
      0,
      1000
  ),
  new PaymentSystem(
      `CRYPTO_PAYMENTS_TRC20_USDT`,
      `Tether USDT (TRC20)`,
      require(`@/assets/img/payment-systems/icons/usdt.png`),
      '',
      '',
      'Пример: TJBFHgJGWyUsHskHGtBCmpXdRPcVjUwb3R',
      /^T[a-zA-Z\d]{33}$/,
      undefined,
      undefined,
      0,
      1,
      500,
      "USDT"
  ),
  new PaymentSystem(
      `CRYPTO_PAYMENTS_ERC20_USDT`,
      `Ethereum USDT (ERC20)`,
      require(`@/assets/img/payment-systems/icons/erc20_usdt.png`),
      '',
      '',
      'Пример: 0x6b7eE97c73F63F800E8bB4e3d1EB4617bD229A95',
      /^0x[a-fA-F0-9]{40}$/,
      undefined,
      undefined,
      0,
      1,
      500,
      "USDT"
  ),
  /*new PaymentSystem(
  `PAYPALYCH_CARD`,
  `VISA / Mastercard / МИР (RUB)`,
  require(`@/assets/img/payment-systems/icons/logo-card.webp`),
  "#### #### #### ####",
  "X",
  null,
  /^\d{16}$/,
  undefined,
  undefined,
  0.03,
  50,
  100
  ),*/
  // new PaymentSystem(
  // `YANDEX`,
  // `ЮMoney (RUB)`,
  // require(`@/assets/img/payment-systems/icons/logo-yamoney.webp`),
  // "################",
  // "X",
  // null,
  // /^41001\d{9,11}$/,
  // undefined,
  // undefined,
  // 0.015,
  // 0,
  // 100
  // ),
  /*new PaymentSystem(
      `ALIKASSA_CARD_UAH`,
      `VISA / Mastercard (UAH)`,
      require(`@/assets/img/payment-systems/icons/logo-card.webp`),
      "#### #### #### ####",
      "",
      'Пример: 4276 3800 5525 2555',
      /^\d{16}$/,
      undefined,
      undefined,
      0.02,
      0,
      100,
      "UAH"
  ) ,*/
  /*new PaymentSystem(
  `CARD_UAH`,
  `VISA / Mastercard (UAH)`,
  require(`@/assets/img/payment-systems/icons/logo-card.webp`),
  "#### #### #### ####",
  "",
  'Пример: 4276 3800 5525 2555',
  /^\d{16}$/,
  undefined,
  undefined,
  0.04,
  0,
  100,
  "UAH"
  ) ,*/
  /*new PaymentSystem(
  `USDT_TRC20`,
  `USDT (TRC20)`,
      require(`@/assets/img/payment-systems/icons/usdt.png`),
  '',
  '',
  'Пример: TJBFHgJGWyUsHskHGtBCmpXdRPcVjUwb3R',
  /^T[a-zA-Z\d]{33}$/,
  undefined,
  undefined,
  0.01,
  0,
      7000,
  "USDT"
  )*/
];

export const depositPaymentSystems: Array<DepositPaymentSystem> = [
  new DepositPaymentSystem(
      `PAYPALYCH_CARD`,
      `Банковская карта`,
      require(`@/assets/img/payment-systems/icons/logo-card.webp`),
      0.03,
      10,
  ),
  new DepositPaymentSystem(
      `PAYPALYCH_QIWI`,
      `Qiwi (RUB)`,
      require(`@/assets/img/payment-systems/icons/logo-qiwi.webp`),
      0.03,
      10,
  ),
  new DepositPaymentSystem(
      `CRYPTO_PAYMENTS_TRC20_USDT`,
      `Tether USDT (TRC20)`,
      require(`@/assets/img/payment-systems/icons/usdt.png`),
      0,
      10,
  ),
  new DepositPaymentSystem(
      `CRYPTO_PAYMENTS_ERC20_USDT`,
      `Ethereum USDT (ERC20)`,
      require(`@/assets/img/payment-systems/icons/erc20_usdt.png`),
      0,
      10,
  ),
 /* new DepositPaymentSystem(
      `PAYPALYCH_USDT`,
      `Tether USDT`,
      require(`@/assets/img/payment-systems/icons/usdt.png`),
      0.03,
      500,
  ),*/



  /*new DepositPaymentSystem(
    `ENOT_QIWI`,
    `Qiwi (RUB)`,
    require(`@/assets/img/payment-systems/icons/logo-qiwi.webp`),
      0.04,
      10
  ),
  new DepositPaymentSystem(
  `ENOT_CARD`,
  `VISA / Mastercard / МИР (RUB)`,
  require(`@/assets/img/payment-systems/icons/logo-card.webp`),
      0.06,
      100,
  ),*/

  // new DepositPaymentSystem(
  // `YANDEX`,
  // `ЮMoney (RUB)`,
  // require(`@/assets/img/payment-systems/icons/logo-yamoney.webp`),
  // 0.02
  // ),
 /* new DepositPaymentSystem(
  `CARD_UAH`,
  `VISA / Mastercard (UAH)`,
  require(`@/assets/img/payment-systems/icons/logo-card.webp`),
      0.04,
  10,
  "UAH"
  ),
  new DepositPaymentSystem(
  `OBMENKA_BTC`,
  `Bitcoin`,
  require(`@/assets/img/payment-systems/icons/logo-bitcoin.webp`),
  0.005,
      250,
  "BTC"
  ),*/
/*  new DepositPaymentSystem(
  `OBMENKA_ETH`,
  `Etherium`,
  require(`@/assets/img/payment-systems/icons/ethereum.png`),
  0,
      250,
  "ETH"
  ),
  new DepositPaymentSystem(
  `OBMENKA_ERC_USDT`,
  `Thether USDT (ERC)`,
  require(`@/assets/img/payment-systems/icons/usdt.png`),
  0,
      250,
  "USDT"
  ),*/
  /*new DepositPaymentSystem(
      `ENOT_USDT`,
      `USDT (TRC20)`,
      require(`@/assets/img/payment-systems/icons/usdt.png`),
      0,
      500,
      "USDT"
  ),*/
  /*new DepositPaymentSystem(
  `OBMENKA_TRC_USDT`,
  `USDT (TRC20)`,
  require(`@/assets/img/payment-systems/icons/usdt.png`),
  0,
      250,
  "USDT"
  )*/
];
