
import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";

@Component({
  apollo: {
    $subscribe: {
      subscribeFirebaseMessagingLocal: {
        query: gql`
          subscription subscribeFirebaseMessagingLocal {
            subscribeFirebaseMessagingLocal
          }
        `,
        result(subscriptionData, key) {
          const payload = subscriptionData.data[key];
          this.notify(payload);
        }
      }
    }
  }
})
export default class FirebaseMessagingLocal extends Vue {
  notify({ notification, data = {} }: { notification: any; data: any }) {
    this.$q.notify({
      color: "white",
      textColor: "black",
      html: true,
        closeBtn: true,
      message: `
        <a style="text-decoration: none !important; max-width: 435px" ${data.url ? 'href="' + data.url + '"' : ""} class='row ${
        data.url ? "cursor-pointer" : "cursor-default"
      }'><div class=' notification-avatar q-pr-lg'><img src='${
        notification.imageUrl
          ? notification.imageUrl
          : "/img/icons/apple-touch-icon-152x152.png"
      }' width='${this.$q.platform.is.mobile ? "50px" : "80px"}' height='${
        this.$q.platform.is.mobile ? "50px" : "80px"
      }'></div><div style="overflow-wrap: break-word;" class='col  notification-message text-black' ><span  class="text-h6">${
        notification.title
      }</span><br><span class='${
        this.$q.platform.is.mobile ? "text-subtitle2" : "text-subtitle1"
      }  text-weight-light'>${notification.body}</span> </div></a>`,
      position: !this.$q.platform.is.mobile ? "bottom-left" : "bottom",
      multiLine: true,
      timeout: 10000
    });
  }
}
