
import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";

class Message {
  type: string = "text";
  meta: string;
  author: string;
  data = { text: "" };
  suggestions: Array<string> = [];
  constructor(
    text: string,
    ourMessage: boolean,
    createdAt: string,
    suggestions: Array<string>
  ) {
    this.meta = createdAt;
    this.author = ourMessage ? "bot" : "me";
    this.data.text = text;
    this.suggestions = suggestions;
  }
}

const CHAT_SECRET_COOKIE_NAME = "_cs";

@Component({
  apollo: {
    dialog: {
      update(data) {
        return data.getDialog;
      },
      query: gql`
        query getDialog($secret: String!) {
          getDialog(secret: $secret) {
            secret
            _id
            createdAt
            messages {
              _id
              text
              suggestions
              createdAt
              ourMessage
            }
          }
        }
      `,
      skip: true,
      variables() {
        return {
          secret: this.dialogSecret
        };
      },
      subscribeToMore: {
        document: gql`
          subscription subscribeMessageAdded($secret: String!) {
            subscribeMessageAdded(secret: $secret) {
              _id
              text
              suggestions
              createdAt
              ourMessage
            }
          }
        `,
        variables() {
          return { secret: this.dialogSecret };
        },
        updateQuery(previousResult, { subscriptionData }) {
          previousResult.getDialog.messages.push(
            subscriptionData.data.subscribeMessageAdded
          );

          if (subscriptionData.data.subscribeMessageAdded.ourMessage) {
            this.showTypingIndicator = "";
          }

          return previousResult;
        }
      }
    }
  }
})
export default class Chat extends Vue {
  dialogSecret: string = "";
  dialog: any = null;

  get defaultMessage() {
    return new Message(
      this.$t("chat.default_message") as string,
      true,
      new Date().toString(),
      [
        "Произошла ошибка",
        "Бот отклоняет обмен",
        "Не прогружается мой инвентарь",
        "Некоторых предметов нет в моем инвентаре"
      ]
    );
  }

  get messages() {
    return [
      this.defaultMessage,
      ...(this.dialog
        ? this.dialog.messages.map(
            ({ _id, text, createdAt, ourMessage, suggestions }) =>
              new Message(text, ourMessage, createdAt, suggestions)
          )
        : [])
    ];
  }

  created() {
    this.dialogSecret = this.$q.cookies.get(CHAT_SECRET_COOKIE_NAME) || "";

    if (this.dialogSecret) {
      this.$apollo.queries.dialog.start();
    }
  }

  async onMessageWasSent({ data: { text } }) {
    if (this.dialog) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createMessage($secret: String!, $text: String!) {
            createMessage(secret: $secret, text: $text) {
              _id
              text
              suggestions
              createdAt
              ourMessage
            }
          }
        `,
        variables: {
          secret: this.dialogSecret,
          text
        }
      });
    } else {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation createDialog($text: String!) {
            createDialog(text: $text) {
              secret
              _id
              createdAt
              messages {
                _id
                text
                suggestions
                createdAt
                ourMessage
              }
            }
          }
        `,
        variables: {
          text
        }
      });
      this.dialogSecret = data.createDialog.secret;
      this.$q.cookies.set(CHAT_SECRET_COOKIE_NAME, data.createDialog.secret, {
        expires: 1,
        path: '/'
      });
      this.$apollo.queries.dialog.start();
    }
    this.showTypingIndicator = "bot";
  }

  openChat() {
    this.$store.commit("toggleChat", true);
  }

  closeChat() {
    this.$store.commit("toggleChat", false);
  }

  get isChatOpen() {
    return this.$store.state.chatOpened;
  }

  mounted() {
    //@ts-ignore
    this.$el
      .querySelector(".sc-user-input--text")
      .setAttribute("placeholder", this.$t("chat.input_placeholder") as string);
  }

  participants = [
    {
      id: "bot",
      name: "Поддержка",
      imageUrl: undefined
    }
  ];
  titleImageUrl = "";
  messageList = [
    { type: "text", author: `me`, data: { text: `Say yes!` } },
    { type: "text", author: `bot`, data: { text: `No.` } }
  ]; // the list of the messages to show, can be paginated and adjusted dynamically
  newMessagesCount = 0;
  showTypingIndicator = ""; // when set to a value matching the participant.id it shows the typing indicator for the specific user
  colors = {
    header: {
      bg: "#498FFF",
      text: "#ffffff"
    },
    launcher: {
      bg: "#498FFF"
    },
    messageList: {
      bg: "#ffffff"
    },
    sentMessage: {
      bg: "#498FFF",
      text: "#ffffff"
    },
    receivedMessage: {
      bg: "#eaeaea",
      text: "#222222"
    },
    userInput: {
      bg: "#f4f7f9",
      text: "#565867"
    }
  }; // specifies the color scheme for the component
  alwaysScrollToBottom = true; // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
  messageStyling = true; // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
}
