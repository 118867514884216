
import {
  Component,
  InjectReactive,
  Inject,
  Vue,
  Prop
} from "vue-property-decorator";
import {
  API_ENDPOINT_SYMBOL,
  INNER_HEADER_HEIGHT_SYMBOL,
  USER_PROFILE_SYMBOL
} from "@/constants";
import TrustPilotRating from "@/components/trustpilot/TrustPilotRating.vue";

@Component({
  components: {
    TrustPilotRating
  }
})
export default class HomeHeader extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  @Inject(API_ENDPOINT_SYMBOL) public apiEndPoint;
  @InjectReactive(INNER_HEADER_HEIGHT_SYMBOL) public innerHeaderHeight;
  public authLoading = false;
}
