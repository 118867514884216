
import { Component, Vue } from "vue-property-decorator";
class Advantage {
  constructor(
    public title: string,
    public image: string,
    public imageHeight: number,
    public imageWidth: number,
    public advantages: string[]
  ) {}
}
@Component({})
export default class AdvantagesFooter extends Vue {
  advantages: Advantage[] = [
    new Advantage(
      this.$t("advantagesFooter.sell_items.title") as string,
      require("../../assets/img/numbers/1.svg"),
      68,
      35,
      this.$t("advantagesFooter.sell_items.advantages") as any
    ),
    new Advantage(
      this.$t("advantagesFooter.get.title") as string,
      require("../../assets/img/numbers/2.svg"),
      69,
      59,
      this.$t("advantagesFooter.get.advantages") as any
    ),
    new Advantage(
      this.$t("advantagesFooter.work.title") as string,
      require("../../assets/img/numbers/3.svg"),
      70,
      58,
      this.$t("advantagesFooter.work.advantages") as any
    )
  ];
}
