var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloMutation',{attrs:{"id":"trade-url-input","mutation":gql => gql`
      mutation updateMyTradeUrl($tradeUrl: String!) {
        updateMyTradeUrl(tradeUrl: $tradeUrl) {
          ...User
        }
      }
      ${_vm.UserFragment}
    `,"variables":{
    tradeUrl: _vm.tradeUrl
  }},on:{"done":() =>
      _vm.$q.notify({
        message: _vm.$t('tradeUrlInput.success_notification'),
        position: _vm.$q.platform.is.mobile ? 'bottom' : 'bottom-left',
        color: 'primary',
        type: 'positive'
      })},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-input',{ref:"input",staticClass:"q-pb-xs",attrs:{"color":"primary","bg-color":"input-bg","disable":!_vm.modelMode ? !_vm.user : false,"input-class":"text-input-color ellipsis","placeholder":"https://steamcommunity.com/tradeoffer/new..","error":!!error,"error-message":error ? _vm.$t('tradeUrlInput.wrong_link') : undefined,"rounded":"","outlined":"","rules":[
        val =>
          !val ||
          /^http(?:s):\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=([0-9]+)&token=([_\-a-zA-Z0-9]+)$/.test(
            val
          ) ||
          _vm.$t('tradeUrlInput.wrong_link_format')
      ]},on:{"input":_vm.onInput},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.$refs.saveTradeUrlBtn ? _vm.$refs.saveTradeUrlBtn.click() : undefined}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.modelMode)?_c('q-btn',{ref:"saveTradeUrlBtn",attrs:{"loading":loading,"disable":!_vm.tradeUrl,"label":!_vm.$q.platform.is.mobile ? _vm.$t('tradeUrlInput.save')  : undefined,"color":"primary","push":"","icon":_vm.$q.platform.is.mobile ? `fas fa-save` : undefined},on:{"click":function($event){return mutate()}}}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.tradeUrl),callback:function ($$v) {_vm.tradeUrl=$$v},expression:"tradeUrl"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }