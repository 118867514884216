
import { Component, Vue } from "vue-property-decorator";
import {
  VAPID_KEY,
  WEB_PUSH_PROPOSAL_TIME
} from "@/components/firebase-messaging/constants";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";

@Component({
  localStorage: {
    deviceId: {
      type: String,
      default: uuidv4()
    },
    firebaseToken: {
      type: String,
      default: ""
    },
    lastWebPushProposalAt: {
      type: Number,
      default: 0
    }
  }
} as any)
export default class FirebaseMessaging extends Vue {
  private $localStorage: any;
  private deviceId: string = this.$localStorage.get("deviceId");
  private firebaseToken: string = this.$localStorage.get("firebaseToken");
  private lastWebPushProposalAt: number = this.$localStorage.get(
    "lastWebPushProposalAt"
  );

  created() {
    if (!Notification) {
      return;
    }

    if (Notification.permission === "granted") {
      this.registerFirebaseToken().catch(e =>
        console.error(`registerFirebaseToken: ${e.message}`)
      );
    } else if (
      Notification.permission === "default" &&
      Date.now() - this.lastWebPushProposalAt > WEB_PUSH_PROPOSAL_TIME &&
      !this.isPartnerPage
    ) {
      this.showWebPushProposal();
    }
  }

  get isPartnerPage() {
    return ["Partner", "PartnerWithPaymentId"].includes(this.$route.name || "");
  }

  async showWebPushProposal() {
    this.$q.notify({
      color: "white",
      textColor: "black",
      html: true,
      message:`
        <div class='row '><div class=' notification-avatar q-pr-lg'><img src='/img/icons/apple-touch-icon-152x152.png' width='${this.$q.platform.is.mobile ? '50px': '80px'}' height='${this.$q.platform.is.mobile ? '50px': '80px'}'></div><div class='col notification-message'><span class='${this.$q.platform.is.mobile ? 'text-subtitle2': 'text-subtitle1'}  text-weight-light'>Подпишитесь на уведомления, чтобы узнавать ${this.$q.platform.is.mobile ? '': '<br>'} об акциях и специальных предложениях первым.</span> </div></div>`,
      position: !this.$q.platform.is.mobile ? "bottom-left" : "bottom",
      multiLine: true,
      actions: [
        {
          label: "Напомнить позже",
          color: "grey",
          size: "14px",
          handler: () => {
            this.$localStorage.set("lastWebPushProposalAt", Date.now());
            this.lastWebPushProposalAt = Date.now();
          }
        },
        {
          label: "Подписаться",
          color: "primary",
          handler: this.registerFirebaseToken,
          flat: false,
          push: true,
          size: "16px"
        }
      ],
      timeout: 100000
    });
  }

  async registerFirebaseToken() {
    const token = await this.requestFirebaseToken();

    return this.$apollo.mutate({
      mutation: gql`
        mutation registerFirebaseToken($deviceId: String!, $token: String!) {
          registerFirebaseToken(deviceId: $deviceId, token: $token)
        }
      `,
      variables: {
        deviceId: this.deviceId,
        token
      }
    });
  }

  async requestFirebaseToken() {
    //@ts-ignore
    const token = await firebase.messaging().getToken({ vapidKey: VAPID_KEY });
    this.$localStorage.set("firebaseToken", token);
    this.firebaseToken = this.$localStorage.get("firebaseToken");

    return token;
  }
}
