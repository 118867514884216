
    import {Component, InjectReactive, Prop, Vue} from "vue-property-decorator";
    import {BALANCE_CURRENCY_SYMBOL} from "@/constants";

    @Component({})
    export default class CurrencyIcon extends Vue {
        @InjectReactive(BALANCE_CURRENCY_SYMBOL)
        public balanceCurrency;
        @Prop() currency?: string;

        get _currency(){
            return this.currency || this.balanceCurrency;
        }
    }
