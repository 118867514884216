
import {
  Component,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import {BALANCE_CURRENCY_SYMBOL, BALANCE_RATES_SYMBOL, USER_PROFILE_SYMBOL} from "@/constants";
import { PaymentSystem } from "@/components/home/PaymentSystem";
import { withdrawPaymentSystems } from "@/components/home/constants";

@Component({})
export default class WithdrawDialog extends Vue {
  @InjectReactive(BALANCE_CURRENCY_SYMBOL) public balanceCurrency;
  @InjectReactive(BALANCE_RATES_SYMBOL) public balanceRates;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  recipient = "";
  paymentSystem = withdrawPaymentSystems[0].value;
  amount: number | undefined = 0;
  paymentSystems: Array<PaymentSystem> = withdrawPaymentSystems;
  @Prop() value;

  @Watch('value')
  @Watch(`paymentSystem`)
  async clearRecipient() {
    this.recipient = ``;
    await this.$nextTick();
    if(this.$refs.amountInput){
      //@ts-ignore
      this.$refs.amountInput.validate();
    }
    this.amount = this.userBalance;
  }

  get minWithdrawAmount() {
    if (!this.selectedPaymentSystem) {
      return Infinity;
    }
    //@ts-ignore
    return this["$options"].filters["convertBalance"](
      this.selectedPaymentSystem.minWithdrawAmount,
      this.selectedPaymentSystem.currency,
      this.balanceRates
    );
  }

  get userBalance() {
    //@ts-ignore
    return !this.user
      ? 0
      : //@ts-ignore
        this.$options.filters.convertBalance(
          this.user.balance,
          //@ts-ignore
          this.selectedPaymentSystem.currency,
          this.balanceRates
        );
  }

  get submitBtnDisable() {
    return (
      !this.selectedPaymentSystem ||
      !this.amount ||
      this.minWithdrawAmount > this.amount ||
      !this.recipient ||
      this.amount > this.userBalance
    );
  }

  get amountWithCommission() {
    if (
      !this.selectedPaymentSystem ||
      (!this.selectedPaymentSystem.commission &&
        !this.selectedPaymentSystem.fixedCommission)
    ) {
      return this.amount;
    }

    const amountWithCommission =
      this.amount === 0
        ? 0
        : Number(
            (
              (this.amount || 0) *
                (1 - (this.selectedPaymentSystem.commission || 0)) -
              (this.selectedPaymentSystem.fixedCommission || 0)
            ).toFixed(2)
          );

    return amountWithCommission >= 0 ? amountWithCommission : 0;
  }

  get selectedPaymentSystem() {
    const selectedPaymentSystem = this.paymentSystems.find(
        ({ value }) => this.paymentSystem === value
    );

    return selectedPaymentSystem || this.paymentSystems[0];
  }

  created() {
    if(this.balanceCurrency === 'UAH') {
      this.paymentSystem = 'CARD_UAH'
    }

    this.amount = this.userBalance;
  }
  get _value() {
    return this.value;
  }

  set _value(v) {
    this.$emit(`input`, v);
  }
}
