import gql from "graphql-tag";

export const UserFragment = gql`
  fragment User on User {
    _id
    name
    avatar
    tradeUrl
    email
    partner
    levelPoints
    levelBonus
    level
    balance
    bonusBalance
    isBonusBalanceAllowed
  }
`;

export const SteamItemFragment = gql`
  fragment SteamItem on SteamItem {
    _id
    name
    price
    image
    rarity
    rarityColor
  }
`;

export const SkinsplusItemFragment = gql`
  fragment SkinsplusItem on SkinsplusItem {
    _id
    name
    price
    image
    exterior
    type
    weaponType
    float
    stickers {
      name
      image
    }
    inspectLink
    steamBotId
  }
`;

export const SteamBotOfferFragment = gql`
  fragment SteamBotOffer on SteamBotOffer {
    _id
    partnerSteamId
    steamItemsIds
    price
    state
    createdAt
    user {
      _id
      name
      avatar
    }
    steamItems {
      _id
      name
      image
    }
    steamItemsPrices
  }
`;
