import Vue from "vue";

import "./styles/quasar.scss";
import iconSet from "quasar/icon-set/fontawesome-v5.js";
import lang from "quasar/lang/ru.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons/material-icons.css";
import Quasar from 'quasar/src/vue-plugin.js';import AddressbarColor from 'quasar/src/plugins/AddressbarColor.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Cookies from 'quasar/src/plugins/Cookies.js';import Meta from 'quasar/src/plugins/Meta.js';;

Vue.use(Quasar, {
  config: {},
  plugins: {
    Notify,
    AddressbarColor,
    Dialog,
    Cookies,
    Meta
  },
  lang: lang,
  iconSet: iconSet
});
