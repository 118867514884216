
  import {Component, Vue, Watch} from "vue-property-decorator";
class Locale {
  constructor(public localeId: string, public image: string) {}
}
const LOCALE_COOKIE_NAME = "_sp_locale";
@Component({})
export default class LocaleSwitcher extends Vue {
  locales = [
    new Locale("ru", require("./assets/ru.svg")),
    new Locale("en", require("./assets/us.svg"))
  ];
  activeLocaleId = "ru";
  $moment: any;
  $i18n: any;

  created() {
    const localeId =
      this.$q.cookies.get(LOCALE_COOKIE_NAME) || this.$i18n.locale;
    const locale = this.locales.find(locale => locale.localeId === localeId);

    if (locale) {
      this.changeLocale(locale.localeId);
    }
  }

  get activeLocale() {
    return (
      this.locales.find(locale => locale.localeId === this.activeLocaleId) ||
      this.locales[0]
    );
  }

  @Watch('$i18n.locale')
  i18nLocaleChanged(locale){
    this.activeLocaleId = locale;
  }

  changeLocale(localeId, reload?:boolean) {
    this.activeLocaleId = localeId;
    this.$q.cookies.set(LOCALE_COOKIE_NAME, localeId, { expires: 365,
      path: '/' });
    this.$i18n.locale = this.activeLocaleId;
    this.$moment.locale(this.$i18n.locale === 'en' ? 'uk' : this.$i18n.locale);

    if(reload){
      document.location.reload();
    }
  }
}
