
  import {Component, InjectReactive, Prop, Vue, Watch} from "vue-property-decorator";
import { PaymentSystem } from "@/components/home/PaymentSystem";
import { paymentSystems } from "./constants";
import {BALANCE_CURRENCY_SYMBOL} from "@/constants";

@Component({
  components: {}
})
export default class Home extends Vue {
  @InjectReactive(BALANCE_CURRENCY_SYMBOL) public balanceCurrency;
  @Prop() public selectedPaymentSystem: PaymentSystem | undefined;
  public paymentSystems: PaymentSystem[] = paymentSystems;

  created(){
    this.change(this.selectedPaymentSystem)
  }

  @Watch('balanceCurrency')
  watchBalanceCurrency(){
    this.change(this.selectedPaymentSystem)
  }

  change(paymentSystem) {
    if(!paymentSystem.value){
      paymentSystem.currency = this.balanceCurrency;
    }
    this.$emit("update:selectedPaymentSystem", paymentSystem);
  }
}
