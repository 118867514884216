
import {
  Component,
  Inject,
  InjectReactive,
  ProvideReactive,
  Vue
} from "vue-property-decorator";
import dom from 'quasar/src/utils/dom.js';import openURL from 'quasar/src/utils/open-url.js';;
import { gsap } from "gsap";
import AuthBtn from "@/components/user/AuthBtn.vue";
import {
  API_ENDPOINT_SYMBOL,
  APP_SCROLL_POS_SYMBOL,
  INNER_HEADER_HEIGHT_SYMBOL,
  RIGHT_DRAWER_SHOW_SYMBOL,
  USER_PROFILE_SYMBOL
} from "@/constants";
import HomeHeader from "@/components/header/HomeHeader.vue";
import FAQHeader from "@/components/header/FAQHeader.vue";
import PartnerHeader from "@/components/header/PartnerHeader.vue";
import BonusHeader from "@/components/header/BonusHeader.vue";
import ProfileHeader from "@/components/header/ProfileHeader.vue";
import PrivacyPolicyHeader from "@/components/header/PrivacyPolicyHeader.vue";
import TermsOfServiceHeader from "@/components/header/TermsOfServiceHeader.vue";
import APIHeader from "@/components/header/APIHeader.vue";
import LocaleSwitcher from "@/components/locale/LocaleSwitcher.vue";
import CurrencySwitcher from "@/components/balance/CurrencySwitcher.vue";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";
import MerchantLoginHeader from "@/components/header/MerchantLoginHeader.vue";
import ReviewsHeader from "@/components/header/ReviewsHeader.vue";

const { height } = dom;

class HeaderLink {
  constructor(
    public label: string,
    public link: string | any,
    public icon?: string,
    public blank?: boolean,
    public authRequired?: boolean
  ) {}
}

const HeaderComponents = {
  Home: HomeHeader,
  FAQ: FAQHeader,
  Reviews: ReviewsHeader,
  Partner: PartnerHeader,
  PartnerWithPaymentId: PartnerHeader,
  Bonus: BonusHeader,
  Profile: ProfileHeader,
  PrivacyPolicy: PrivacyPolicyHeader,
  TermsOfService: TermsOfServiceHeader,
  API: APIHeader,
  MerchantLogin: MerchantLoginHeader,
  Buy: HomeHeader
};

@Component({
  components: {
    AuthBtn,
    LocaleSwitcher,
    CurrencySwitcher,
    CurrencyIcon,
    Amount
  }
})
export default class BaseHeader extends Vue {
  public openURL: any = openURL;
  public authLoading = false;
  public headerRevealed = true;
  public get headerLinks(): Array<HeaderLink> {
    return [
      new HeaderLink(this.$t("baseHeader.sell") as string, "/"),
      new HeaderLink(this.$t("baseHeader.buy") as string, "/buy"),
      new HeaderLink("faq", "/faq"),
      new HeaderLink(this.$t("baseHeader.reviews") as string,"/reviews"),
      new HeaderLink(this.$t("baseHeader.bonuses") as string, "/bonus"),
      new HeaderLink(
        this.$t("baseHeader.deposit") as string,
        $route => $route.path + `#deposit`,
        undefined,
        undefined,
        true
      ),
      new HeaderLink(
        this.$t("baseHeader.withdraw") as string,
        $route => $route.path + `#withdraw`,
        undefined,
        undefined,
        true
      )
      //new HeaderLink(this.$t('baseHeader.support') as string, "/support"),
      //new HeaderLink("", "https://vk.com", "fab fa-vk", true),
      //new HeaderLink("", "https://instagram.com", "fab fa-instagram", true)
    ];
  }

  @Inject(API_ENDPOINT_SYMBOL) public apiEndPoint: string | undefined;
  @InjectReactive(APP_SCROLL_POS_SYMBOL) public appScrollPos:
    | number
    | undefined;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  @InjectReactive(RIGHT_DRAWER_SHOW_SYMBOL) public rightDrawerShow:
    | boolean
    | undefined;
  @ProvideReactive(INNER_HEADER_HEIGHT_SYMBOL)
  public innerHeaderHeight = 0;
  public lastHeaderHeight = 0;

  get isPartnerPage() {
    return ["Partner", "PartnerWithPaymentId"].includes(
      this.currentRouteHeaderName
    );
  }

  onInnerHeaderResize({ height }) {
    this.innerHeaderHeight = height;
  }

  headerComponentLeave() {
    this.lastHeaderHeight = this.$el.clientHeight;

    if (this.appScrollPos || 0 > this.innerHeaderHeight) {
      this.$scrollTo(this.$el, 100);
    }
  }

  beforeHeaderComponentEnter(element) {
    const lastHeight = this.lastHeaderHeight;

    requestAnimationFrame(() => {
      let clone = element.cloneNode(true),
        nextHeight;

      clone.style.width = element.style.width;
      clone.style.visibility = "hidden";
      clone.style.removeProperty("display");

      // get clone height
      element.parentNode.appendChild(clone);
      nextHeight = clone.clientHeight + this.innerHeaderHeight;

      clone.remove();

      gsap
        .timeline({ ease: "linear" })
        .fromTo(
          this.$el,
          { height: lastHeight, duration: 0 },
          { height: nextHeight, duration: 125 / 1000 }
        )
        .to(this.$el, { height: "auto", duration: 0 });
    });
  }

  get currentRouteHeaderName() {
    const names = Object.keys(HeaderComponents);
    const index = names.indexOf(String(this.$route.name));
    return index === -1 ? names[0] : names[index];
  }

  get currentRouteHeaderComponent() {
    return HeaderComponents[this.currentRouteHeaderName];
  }
}
