var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-dialog',{attrs:{"id":"deposit-dialog"},model:{value:(_vm._value),callback:function ($$v) {_vm._value=$$v},expression:"_value"}},[_c('q-card',{staticClass:"app-dialog q-pb-xl",style:(`min-width: ${_vm.$q.platform.is.mobile ? 350 : 450}px`)},[_c('q-card-section',{staticClass:"row items-end dialog-title"},[_c('div',{staticClass:"text-h5 text-weight-bold text-white"},[_vm._v(" Пополнение баланса "),_c('br'),_c('span',{staticClass:"text-weight-light"},[_vm._v("заполните форму")])]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":"","color":"white"}})],1),_c('q-card-section',{staticClass:"dialog-content",staticStyle:{"padding-bottom":"40px"}},[_c('ApolloMutation',{attrs:{"mutation":gql => gql`
            mutation createDepositInvoice(
              $amount: Float!
              $paymentSystem: UserBalanceDepositPaymentSystem!
              $code: String
            ) {
              createDepositInvoice(
                amount: $amount
                paymentSystem: $paymentSystem
                code: $code
              ) {
                payLink
              }
            }
          `,"variables":{
          paymentSystem: _vm.paymentSystem,
          ...(_vm.code ? { code: _vm.code } : {}),
          amount: _vm.$options.filters.convertBalanceInverse(
            _vm.amount,
            _vm.selectedPaymentSystem.currency,
            _vm.balanceRates
          )
        }},on:{"error":error =>
            _vm.$q.notify({
              message: `Произошла ошибка, обратитесь в поддержку. ${error}`,
              multiLine: true,
              type: 'negative'
            }),"done":result => {
            _vm.$q.notify({
              message: `Вы будете перенаправлены на страницу оплаты.
              Если это не происходит, нажмите по <a href='${result.data.createDepositInvoice.payLink}' class='text-weight-bold text-primary cursor-pointer' style='text-decoration: underline' target='_blank' rel='noreferrer' ref='payLink'>ссылке</a>.`,
              html: true,
              multiLine: true,
              type: 'positive'
            });
            _vm.openURL(result.data.createDepositInvoice.payLink);
            this.code = '';
          }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading, error }){return [_c('q-form',{ref:"form",on:{"submit":function($event){return mutate()}}},[_c('div',{staticClass:"text-subtitle-color text-subtitle1 q-mb-sm"},[_vm._v(" Платежная система ")]),_c('q-select',{staticClass:"q-mb-md",attrs:{"emit-value":"","options":_vm.paymentSystems,"map-options":"","option-value":"value","color":"primary","dark":"","outlined":"","rounded":"","bg-color":"transparent"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-avatar',[_c('img',{attrs:{"src":_vm.selectedPaymentSystem.image}})])]},proxy:true},{key:"option",fn:function(scope){return [_c('q-item',_vm._g(_vm._b({},'q-item',scope.itemProps,false),scope.itemEvents),[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-avatar',[_c('img',{attrs:{"src":scope.opt.image}})])],1),_c('q-item-section',[_c('q-item-label',[_vm._v(" "+_vm._s(scope.opt.label)+" ")])],1)],1)]}}],null,true),model:{value:(_vm.paymentSystem),callback:function ($$v) {_vm.paymentSystem=$$v},expression:"paymentSystem"}}),_c('div',{staticClass:"text-subtitle-color text-subtitle1 q-mb-sm flex items-center"},[_c('span',[_vm._v("Сумма пополнения")]),_c('q-space'),_c('span',{staticClass:"text-subtitle2"},[_vm._v("мин.: "),_c('span',{staticClass:"text-positive text-weight-medium"},[_vm._v(_vm._s(_vm.minDepositAmount)+" "+_vm._s(_vm.selectedPaymentSystem.currency))])])],1),_c('q-input',{ref:"amountInput",attrs:{"outlined":"","color":"primary","placeholder":'Введите сумму пополнения',"clearable":"","rules":[
                val =>
                  _vm.minDepositAmount <= Number(val) ||
                  `Минимальная сумма пополнения ${_vm.minDepositAmount} ${_vm.selectedPaymentSystem.currency}`,
                val =>
                  /^\d+(\.\d{1,8})?$/i.test(val) ||
                  `Сумма пополнения введена не верно, пример: 12.34`
              ],"bg-color":"input-bg","rounded":"","input-class":"text-input-color ellipsis"},on:{"change":e => {
                  if (Number(e.target.value) < 0) {
                    _vm.amount = 0;
                  }
                }},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}}),_c('div',{staticClass:"text-subtitle-color text-subtitle1 q-mb-sm flex items-center"},[_c('span',[_vm._v("Вы получите")]),_c('q-space'),(_vm.selectedPaymentSystem.commission)?_c('span',{staticClass:"text-subtitle2"},[_vm._v("комиссия: "),_c('span',{staticClass:"text-positive text-weight-medium"},[_vm._v(" "+_vm._s(Number( (_vm.selectedPaymentSystem.commission * 100).toFixed(2) ))+"% ")])]):_vm._e()],1),_c('q-input',{attrs:{"outlined":"","color":"primary","value":_vm.amountWithCommission,"readonly":"","bg-color":"input-bg","rounded":"","input-class":"text-input-color ellipsis"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CurrencyIcon',{staticStyle:{"color":"#3CF08F"}})]},proxy:true}],null,true)}),_c('a',{ref:"payLink",staticStyle:{"visibility":"hidden"},attrs:{"href":_vm.payLink,"target":"_blank","rel":"noreferrer"}},[_vm._v("a")]),_c('div',{staticClass:"text-subtitle-color text-subtitle1 q-mb-sm flex items-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("homeBalanceWithdraw.promocode")))]),_c('q-space'),(_vm.promocode)?_c('span',{staticClass:"text-subtitle2"},[_vm._v(_vm._s(_vm.$t("homeBalanceWithdraw.bonus"))+" "),_c('span',{staticClass:"text-positive text-weight-medium"},[_vm._v(" +"+_vm._s(_vm.promocode.percentage * 100)+"% ")])]):_vm._e()],1),_c('q-input',{attrs:{"outlined":"","color":"primary","error":!!_vm.code && !_vm.promocode,"error-message":_vm.$t('homeBalanceWithdraw.promocode_error'),"placeholder":'Введите промокод',"clearable":"","bg-color":"input-bg","rounded":"","input-class":{
                'text-uppercase': !!_vm.code,
                'text-input-color ellipsis': true
              }},model:{value:(_vm.code),callback:function ($$v) {_vm.code=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"code"}}),_c('q-btn',{staticClass:"absolute",staticStyle:{"left":"50%","transform":"translateX(-50%)","bottom":"-62px"},attrs:{"loading":loading,"disable":_vm.submitBtnDisable,"type":"submit","label":"Пополнить баланс","color":"primary","push":"","size":"lg","no-wrap":""}})],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }