import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chatOpened: false,
  },
  mutations: {
    toggleChat(state, value){
      state.chatOpened = typeof value === 'boolean' ? value : !state.chatOpened
    }
  },
  actions: {},
  modules: {}
});
