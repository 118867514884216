var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{staticClass:"full-width",attrs:{"id":"live-offers","query":gql => gql`
      query getSteamBotDepositOffers($partnerUserId: ID, $nextPageToken: ID) {
        getSteamBotDepositOffers(
          partnerUserId: $partnerUserId
          nextPageToken: $nextPageToken
        ) {
          ...SteamBotOffer
        }
      }
      ${_vm.SteamBotOfferFragment}
    `,"variables":{ partnerUserId: _vm.partnerUserId }},on:{"result":_vm.onResult},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data }, isLoading, query }){return [(
        data &&
          data.getSteamBotDepositOffers &&
          data.getSteamBotDepositOffers.length
      )?_c('div',{staticClass:"wrapper q-py-lg overflow-hidden"},[_c('div',{staticClass:"text-h5 q-mb-lg"},[_c('span',{staticClass:"text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("liveOffers.last"))+" ")]),_c('span',{staticClass:"text-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("liveOffers.offers"))+" ")])]),_c('div',{directives:[{name:"touch-pan",rawName:"v-touch-pan.horizontal.mouse",value:(_vm.handlePan),expression:"handlePan",modifiers:{"horizontal":true,"mouse":true}}],ref:"pan",staticClass:"scroll"},[_c('q-scroll-observer',{key:'scroll-observer',attrs:{"horizontal":""},on:{"scroll":function($event){return _vm.scrollHandler(query)}}}),_c('slide-x-left-transition',{staticClass:"flex no-wrap",attrs:{"group":""}},[_vm._l((data.getSteamBotDepositOffers),function(offer,i){return [_c('q-intersection',{key:offer._id,staticClass:"offer-container",attrs:{"once":i < 15}},[_c('LiveOffer',{attrs:{"value":offer}})],1),(i !== data.getSteamBotDepositOffers.length - 1)?_c('q-separator',{key:i,staticClass:"q-mx-md",attrs:{"dark":"","vertical":""}}):_vm._e()]})],2)],1)]):_vm._e()]}}])},[_c('ApolloSubscribeToMore',{attrs:{"document":gql => gql`
        subscription subscribeDepositSteamBotOfferAdded($partnerUserId: ID) {
          subscribeDepositSteamBotOfferAdded(partnerUserId: $partnerUserId) {
            ...SteamBotOffer
          }
        }
        ${_vm.SteamBotOfferFragment}
      `,"variables":{ partnerUserId: _vm.partnerUserId },"updateQuery":_vm.onDepositSteamBotOfferAdded}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }