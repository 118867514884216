import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import Transitions from "vue2-transitions";
import VueScrollTo from "vue-scrollto";
import "./quasar";
import vueAwesomeCountdown from "vue-awesome-countdown";
import moment from "moment";
import VueMoment from "vue-moment";
import VueLocalStorage from "vue-localstorage";
import i18n from "./i18n";
import Chat from "vue-beautiful-chat";
import errorCode from "@/mixins/errorCode";
require("moment/locale/uk");
require("moment/locale/ru");
require("./filters");

Vue.mixin(errorCode);
Vue.use(Chat);
Vue.use(VueLocalStorage);
Vue.use(VueMoment, {
  moment
});

Vue.use(vueAwesomeCountdown, "vac"); // Component name, `countdown` and `vac` by default
Vue.config.productionTip = false;

Vue.use(Transitions);
Vue.use(VueScrollTo, {
  container: "#app-scroll > .scroll"
});

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  i18n,
  render: h => h(App)
}).$mount("#app");
