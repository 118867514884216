import { render, staticRenderFns } from "./BaseDrawer.vue?vue&type=template&id=48a415af&scoped=true&"
import script from "./BaseDrawer.vue?vue&type=script&lang=ts&"
export * from "./BaseDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./BaseDrawer.vue?vue&type=style&index=0&id=48a415af&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a415af",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QList,QBtn,QAvatar,QImg,QSeparator,QItem,QItemSection,QIcon});qInstall(component, 'directives', {ClosePopup,Ripple});
