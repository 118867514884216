const ERRORS_OBJECT = {
  //"GraphQL error: bot was not found": "Временно ограничен прием скинов, ввиду отсутствия возможности вывода на популярные направления.",
  "GraphQL error: bot was not found": "Временно не принимаем скины от данной игры. Если вы сталкиваетесь с проблемой не первый раз, обратитесь в поддержку",
  "GraphQL error: Internal error": "Возможно Ваш инвентарь скрыт, обновите настройки конфиденциальности в вашем профиле Steam или попробуйте обновить, код ошибки - 0xie0500",
  "GraphQL error: Internal server error": "Возможно Ваш инвентарь скрыт, обновите настройки конфиденциальности в вашем профиле Steam или попробуйте обновить, код ошибки - 0xie0500",
  "GraphQL error: Couldn't connect to server": "Попробуйте обновить, код ошибки - 0xccs500"
};

export default {
  methods: {
    getErrorCodeByMessage(message: string) {
      return ERRORS_OBJECT[message] || message;
    }
  }
};
