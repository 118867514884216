
import {
  Component,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import {BALANCE_CURRENCY_SYMBOL, BALANCE_RATES_SYMBOL, USER_PROFILE_SYMBOL} from "@/constants";
import * as _ from "lodash";
import { PaymentSystem } from "@/components/home/PaymentSystem";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";
import gql from "graphql-tag";

@Component({
  components: {
    Amount,
    CurrencyIcon
  },
  apollo: {
    promocode: {
      update(data) {
        return data.getPromocode;
      },
      query: gql`
        query($code: String!) {
          getPromocode(code: $code) {
            code
            percentage
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          code: this.code
        };
      },
      skip() {
        if (!this.code) {
          this.promocode = null;
        }

        return !this.code;
      }
    }
  }
})
export default class Home extends Vue {
  @InjectReactive(BALANCE_CURRENCY_SYMBOL) public balanceCurrency;
  @InjectReactive(BALANCE_RATES_SYMBOL) public balanceRates;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;
  @Prop({ default: 730 }) appId!: number;
  @Prop() steamItemCommission: { [x in number | string]: number } | undefined;
  @Prop() selectedSteamItems: any;
  @Prop() offerPrice: number | undefined;
  @Prop() public selectedPaymentSystem: PaymentSystem | undefined;
  public emailRegExp = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  public emailSubscription = true;
  public email = "";
  code = "";
  promocode = null;
  @Prop() public recipient: string | undefined;
  @Prop() public steamId: string | undefined;
  @Prop() public partnerOfferPaymentData:
    | { paymentId: string; userId: string; minAmount: number; sign: string }
    | undefined;

  created() {
    this.email = this.user ? this.user.email : "";
  }

  @Watch("selectedPaymentSystem")
  resetRecipient() {
    this.$emit("update:recipient", "");
  }

  get _offerPrice() {
    if (typeof this.offerPrice === "number") {
      return this.offerPrice;
    }

    return this.selectedSteamItems
      ? Number(
          _.sum(
            this.selectedSteamItems.map(steamItem => steamItem.price)
          ).toFixed(2)
        )
      : 0;
  }

  get offerCommission() {
    return Number(
      (this._offerPrice - this.offerPriceWithCommission).toFixed(2)
    );
  }

  get submitBtnDisabled() {
    return this.steamId
      ? !this.selectedPaymentSystem ||
          this.selectedPaymentSystem.minWithdrawAmount > this._offerPrice
      : !this.selectedPaymentSystem ||
          this.selectedPaymentSystem.minWithdrawAmount > this._offerPrice ||
          (!this.recipient && !!this.selectedPaymentSystem.mask) ||
          !this.email || (!!this.code && !this.promocode)

  }

  get bonusPrice() {
    return this.steamId
      ? 0
      : this.user
      ? Number(
          (
            this.user.levelBonus *
            ((this.offerPrice || 0) /
              (this.steamItemCommission
                ? this.steamItemCommission[this.appId]
                : 1))
          ).toFixed(2)
        )
      : 0;
  }

  get offerPriceWithCommission() {
    if (!this.selectedPaymentSystem) {
      return this._offerPrice;
    }

    if (
      !this.selectedPaymentSystem.commission &&
      !this.selectedPaymentSystem.fixedCommission
    ) {
      return this._offerPrice;
    }

    const fixedCommission = (this.selectedPaymentSystem.fixedCommission || 0) * this.balanceRates[this.selectedPaymentSystem.currency || 'RUB'] ;
    
    return this._offerPrice === 0
      ? 0
      : Number(
          (
            this._offerPrice *
              (1 - (this.selectedPaymentSystem.commission || 0)) -
            fixedCommission
          ).toFixed(2)
        );
  }
}
