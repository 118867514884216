import { render, staticRenderFns } from "./LocaleSwitcher.vue?vue&type=template&id=01e2786f&"
import script from "./LocaleSwitcher.vue?vue&type=script&lang=ts&"
export * from "./LocaleSwitcher.vue?vue&type=script&lang=ts&"
import style0 from "./LocaleSwitcher.vue?vue&type=style&index=0&id=01e2786f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtnDropdown,QList,QItem,QItemSection,QImg});qInstall(component, 'directives', {ClosePopup});
